import React, { useState, useEffect } from "react";
import { Row, Container, Col } from "react-bootstrap";
import { useAppData, AppContextInterface } from "../../../hooks/useAppData";
import notification from "../../Notification";
import "./EditBackground.scss";
import { AddImageModal } from "../AddImageModal/AddImageModal";
import { ReactComponent as AddImageIcon } from "./AddImageIcon.svg"
import { ReactComponent as AddVideoIcon } from "./AddvideoIcon.svg"
import ColorPicker from "react-gradient-color-picker-ts";
import { custombackgroundImages, custombackgroundVideos, imagesFolderName, maxMediaSize, videosFolderName } from "../../../utils/StaticData/staticData";
import axiosApi from "../../../axiosApi";
import CircularProgress from "../../CircularProgress/CircularProgress";

interface Props {
  setAnimationLoader: (select: any) => void;
  animationLoader: any;
}

const profileImageData = [
  {
    id: 1,
    name: 'Photo',
    key: 'image',
    image: <AddImageIcon />
  },
  {
    id: 2,
    name: 'Video',
    key: 'video',
    image: <AddVideoIcon />
  },
]

export const EditBackground: React.FC<Props> = (props) => {
  const {
    state,
    setProfileBackground,
    profileBackground,
    isActiveSubscription,
    abortS3Upload,
    uploadOnS3,
    deleteFromS3,
    setUploadLoader
  } = useAppData() as AppContextInterface;

  // const [color, setColor] = useState(profileBackground);
  const [open, setOpen] = useState(false);
  const [imageModalDetails, setImageModalDetails] = useState(false);
  const [videoModalDetails, setVideoModalDetails] = useState(false);
  const [imageLoading, setImageLoading] = useState<number>(0);
  const [customMedia, setCustomMedia] = useState<any>({});
  const impactRef = React.useRef<HTMLInputElement>(null);

  const bucketName = process.env.REACT_APP_AWS_BUCKET;
  const accessToken = localStorage.getItem('accessToken');

  const customHeaders = {
    headers: { Authorization: `Bearer ${accessToken}` }
  }

  useEffect(() => {
    if (isActiveSubscription.active) {
      getAllCustomImagesVideos()
    } else {
      setCustomMedia({
        images: custombackgroundImages,
        videos: custombackgroundVideos,
      })
    }
  }, [isActiveSubscription.active])

  const uploadCustomPhotoVideo = async (imageUrl: string, imageOrVideo: any) => {
    const data: any = {
      background_media: imageUrl,
      type: imageOrVideo
    }
    try {
      await axiosApi.post(`user/user-media`, data, customHeaders)
      setProfileBackground(imageUrl);
      notification({
        message: `${imageOrVideo === "image" ? "Image" : "Video"
          } Successfully Uploaded`,
        type: "success",
      });
      getAllCustomImagesVideos()
      setUploadLoader(false);
      setImageLoading(0)
    }
    catch (err) {
      setUploadLoader(false);
      setImageLoading(0)
    }

  }

  const deleteCustomPhotoVideo = async (item: any, imageOrVideo: string) => {
    setImageModalDetails(false)
    setVideoModalDetails(false)
    setUploadLoader(true);

    const imageId = item?.id
    const imageNameIndx = item?.background_media.lastIndexOf("/")
    let imageName = item?.background_media?.slice(imageNameIndx, item?.background_media?.length)

    const currentPhoto = state?.selectedProfile?.background_color
    let currentPhotoVideoIndex = currentPhoto.lastIndexOf("/");
    let currentPhotoVideo = currentPhoto?.slice(currentPhotoVideoIndex, currentPhoto?.length)

    try {
      const folderName = imageOrVideo === "image" ? imagesFolderName : videosFolderName
      if (currentPhotoVideo === imageName) {
        notification({
          message: `Cannot delete default ${imageOrVideo === "image" ? "image" : "video"
            }`,
          type: "warning",
        });
        setUploadLoader(false);
        return false
      }
      const params: any = {
        Bucket: bucketName,
        Key: `${folderName}${imageName}`
      };

      await axiosApi.delete(`user/user-media/${imageId}`)
      deleteFromS3(params, (status: boolean) => {
        if (!status) {
          setUploadLoader(false);
          return false
        } else {
          notification({
            message: `${imageOrVideo === "image" ? "Image" : "Video"
              } Successfully Deleted`,
            type: "success",
          });
          let mediaType = imageOrVideo === "image" ? 'images' : 'videos'
          let mediaIndex = customMedia?.[mediaType].findIndex((item: any) => item.id == imageId)
          if (mediaIndex >= 0) {
            let newData: any = [...customMedia?.[mediaType]]
            newData.splice(mediaIndex, 1)
            setCustomMedia((prev: any) => ({ ...prev, [mediaType]: newData }))
          }
          setUploadLoader(false);
        }
      })
    }
    catch (err) {
      setUploadLoader(false);
    }

  }

  const handleUploadChange = async (e: any, imageOrVideo: string) => {
    setImageModalDetails(false)
    setVideoModalDetails(false)
    let mediaType = imageOrVideo === "image" ? 'images' : 'videos'
    let mediaLength = customMedia?.[mediaType]?.filter((item: any) => item.background_media).length

    if (mediaLength < 5) {
      const imageFiles = e.target.files;
      if (!imageFiles.length) {
        notification({
          message: "Choose a File to Upload",
          type: "info",
        });
      }
      if (imageFiles[0].size > maxMediaSize[imageOrVideo]) {
        notification({
          message: `Max limit of ${imageOrVideo} size is ${maxMediaSize[imageOrVideo] / 1000000}Mb`,
          type: "danger",
        });
        return false
      }
      setImageLoading(1)
      const isImage = imageOrVideo === "image" ? true : false
      const fileName = `${state.selectedProfile.user_id.toString()}_${new Date().getTime()}_${isImage
        ? "profileBackgroundImage."
        : "profileBackgroundVideo."
        }${imageFiles[0].name.split(".").pop()}`;

      const params = {
        Bucket: bucketName ? bucketName : "",
        Key: fileName ? `${isImage ? imagesFolderName : videosFolderName}/${fileName}` : "",
        Body: imageFiles[0] ? imageFiles[0] : null,
      };

      uploadOnS3(params, (status: boolean, progress: number) => {
        if (status) {
          let imageURL = `https://${bucketName}.s3.amazonaws.com/${isImage ? imagesFolderName : videosFolderName}/${fileName}`;
          uploadCustomPhotoVideo(imageURL, imageOrVideo)
        } else if (!status && progress) {
          setImageLoading(progress)
        }
        else {
          setImageLoading(0)
          notification({
            message: `${imageOrVideo === "image" ? "Image" : "Video"
              } Upload Failed`,
            type: "danger",
          });
        }
      })
    } else {
      notification({
        message: `Max 5 custom ${imageOrVideo === "image" ? "Image" : "Video"
          } can be uploaded`,
        type: "danger",
      });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (!impactRef?.current?.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [impactRef]);

  const getAllCustomImagesVideos = async () => {
    Promise.allSettled([axiosApi.get(`user/user-media?type=video`, customHeaders), axiosApi.get(`user/user-media?type=image`, customHeaders)])
      .then((res) => {
        let newObj: any = {};
        let newImages = [...custombackgroundImages]
        newImages.splice(0, 1)
        let newVideos = [...custombackgroundVideos]
        newVideos.splice(0, 1)
        res.map((item) => {
          if (item.status === 'fulfilled') {
            if (item.value.config.url?.includes('video')) {
              newObj['videos'] = [custombackgroundImages[0], ...item.value.data.data, ...newVideos]
            } else {
              newObj['images'] = [custombackgroundVideos[0], ...item.value.data.data, ...newImages]
            }
          }
        })
        setCustomMedia(newObj)
      }).catch(err => {
        setCustomMedia({
          images: custombackgroundImages,
          videos: custombackgroundVideos,
        })
      })
  }

  const handleModal = (item: any) => {
    if (item.key == 'image') {
      setImageModalDetails(!imageModalDetails)
    } else {
      setVideoModalDetails(!videoModalDetails)
    }
  }

  const abortUpload = () => {
    abortS3Upload()
    setImageLoading(0)
  }

  return (
    <>
      <Container className="background-container">
        <Row>
          <Col className="container-col" style={{ position: "relative" }}>
            <div
              className="default-background"
              onClick={() => setOpen(!open)}
            />
            {open ? (
              <div className="box" ref={impactRef}>
                <ColorPicker
                  value={profileBackground}
                  onChange={setProfileBackground}
                  hideInputs={true}
                  hidePresets={true}
                  hideAdvancedSliders={true}
                  hideColorGuide={true}
                  hideInputType={true}
                />
              </div>
            ) : null}
            <div className="select-background-text">Gradient Background</div>
          </Col>
          {profileImageData.map((item) =>
            <Col className="container-col" key={item.id}>
              <div className="photo-background-icon" onClick={() => handleModal(item)}>
                {item.image}
              </div>
              <div className="select-background-text">{`${item?.name} Background`}</div>
            </Col>
          )}
        </Row>
        {imageLoading !== 0 &&
          <div className="circular-progress-bar">
            <CircularProgress
              size={100}
              strokeWidth={7}
              percentage={imageLoading}
              color="green"
            />
            <span className="normal-text sub-heading" onClick={abortUpload}>Cancel</span>
          </div>
        }
        <AddImageModal
          modalData={customMedia?.images}
          show={imageModalDetails}
          title={'image'}
          setShow={(value) => setImageModalDetails(value)}
          deleteCustomPhotoVideo={deleteCustomPhotoVideo}
          handleUploadChange={handleUploadChange}
        />
        <AddImageModal
          modalData={customMedia?.videos}
          show={videoModalDetails}
          title={'video'}
          setShow={(value) => setVideoModalDetails(value)}
          deleteCustomPhotoVideo={deleteCustomPhotoVideo}
          handleUploadChange={handleUploadChange}
        />
      </Container>
    </>
  );
};
