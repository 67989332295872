// @ts-nocheck
import "./Profile.scss";

import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import imageCompression from 'browser-image-compression'
import { Container, Form, Image, Spinner } from "react-bootstrap";
import { ProfileSocial } from "../ProfileSocial/ProfileSocial";
import { AddContactCard } from "../AddContactCard/AddContactCard";
import { NotFound } from "../NotFound/NotFound";
import notification from "../Notification";
import axiosApi from "../../axiosApi";
import { imagesFolderName, videosFolderName } from "../../utils/StaticData/staticData";
import { checkSerialNumber, getAccessToken, getSocialIcon } from "../../utils/global/global";
import { googleAnalyticsCustomEvent } from "../../utils/google-analytics";
import TappyConnect from "../TappyConnect/TappyConnect";
import { AppContextInterface, useAppData } from "../../hooks/useAppData";

interface ContactCard {
  id: number;
  profile_id: number;
  first_name: string;
  last_name: string;
  company: string;
  phones: {
    cell_phone: string[];
    pager_phone: string[];
    work_phone: string[];
    home_phone: string[];
  };
  email: string;
  website: string;
  address: string;
  birthday: string;
  notes: string;
  pin_active: boolean;
  security_pin: string;
  security_pin_exists: boolean;
}

interface Pivot {
  profile_id: number;
  social_id: number;
  username: string;
}

interface Social {
  id: number;
  name: string;
  pivot: Pivot;
}

interface Profile {
  id: number;
  user_id: number;
  name: string;
  bio: string;
  photo: string;
  created_at: string;
  updated_at: string;
  social_order: string;
  socials: Social[] | null;
  pin_active: boolean;
  contactCard: ContactCard;
  theme: string;
}

interface State {
  session: number;
  userData: any;
  selectedProfile: Profile | null;
}

interface Props {
  state: State;
  setState: React.Dispatch<React.SetStateAction<any>>;
  setLoading: (status: boolean) => void;
  handleHistory: (url: string) => void;
}

export const ProfilePage: React.FC<Props> = (props) => {
  const params: any = useParams();
  const {
    uploadOnS3,
    setUploadLoader,
    deleteFromS3,
    uploadLoader
  } = useAppData() as AppContextInterface;
  const [profile, setProfile] = useState(null as any);
  const button_color = profile && profile?.button_color ? JSON.parse(profile.button_color) : { r: 31, g: 35, b: 40, a: 0.3 };
  const [contactName, setContactName] = useState("name" as any);
  const [cardContents, setCardContents] = useState("contents" as any);

  const [profileExists, setProfileExists] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerHeight);
  const tappyModalRef = useRef<any>(null)
  const [showModal, setShowModal] = useState(false)
  const isPremium = profile?.subscriptionStatus || false;
  const divElement =
    document.getElementById("view-profile-page")?.clientHeight || 0;

  useEffect(() => {

    const handleWindowResize = () => {
      setWindowWidth(window.innerHeight);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  useEffect(() => {
    async function checkProfile() {
      if (window.location.pathname === "/profile") {
        if (props.state.userData && props.state.session > -1) {
          await loadUserProfile(props.state.userData.username, "username", false);
        }
      } else {
        // User name cannot be the same format as serial_id, which is either
        // AA0000, AA00000, 0000AA, 00000AA, A0000, A00000, 0000, 00000
        if (checkSerialNumber(params.username)) {
          console.log("Redirecting to tappyActivation");
          await loadUserProfile(params.username, "serial", false);
        } else {
          await loadUserProfile(params.username, "username", false);
        }
      }
    }

    checkProfile();
  }, []);

  const [scrollDir, setScrollDir] = useState("up");

  useEffect(() => {
    const threshold = 0;
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      setScrollDir(scrollY > lastScrollY ? "down" : "up");
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener("scroll", onScroll);
    //console.log(scrollDir);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollDir]);

  const loadUserProfile = async (inputString: string, inputType: string, isConnect?: boolean) => {

    try {
      isConnect === false && props.setLoading(true);

      const response = await axiosApi.get(
        `profile/${inputType}/${inputType == "serial" ? inputString.toUpperCase() : inputString
        }`
      );

      if (response.data.message) {
        // means either profile does not exists or product is available
        if (response.data.message !== "available") {
          setProfileExists(false);
          notification({
            message: response.data.message,
            type: "warning",
            insert: "bottom",
            container: "bottom-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2500,
            },
          });
        } else {
          props.handleHistory(
            "/tappyActivation?serial=" + inputString.toUpperCase()
          );
        }
      } else {
        // means it definitely replies a profile
        const profileId = response.data.id;
        const userId = response.data.user_id;
        const profileSocials = await axiosApi.get(
          `user/${userId}/profile/${profileId}/socials`
        );
        const profileContactCard = await axiosApi.get(
          `user/${userId}/profile/${profileId}/contact`
        );
        const newProfile = { ...response.data };
        newProfile.socials = profileSocials.data;
        newProfile.contactCard = profileContactCard.data;
        let event = {}
        if (inputType === "serial") {
          event = {
            action: `profile_view`,
            value: `${profileContactCard?.data?.username}/${inputString}`,
            key: 'profile_views'
          }

        } else {
          event = {
            action: `share_profile_view`,
            value: inputString,
            key: 'share_profile'
          }
        }
        googleAnalyticsCustomEvent(event)

        var ordered = reorderSocials(
          newProfile.socials,
          newProfile.social_order
        );

        newProfile.socials = ordered;
        isConnect === false && setProfile(newProfile);
        await loadvCard(newProfile, isConnect);
        isConnect === false && props.setLoading(false);
      }
    } catch (error) {
      if (isConnect === false) {
        setProfileExists(false);
        notification({
          message: "Failed to fetch profile, please check your url",
          type: "warning",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2500,
          },
        });

        props.setLoading(false);
      } else {
        setUploadLoader(false)
      }
    }
  };

  const [toggleAddContactCard, setToggleAddContactCard] = useState(false);

  const onHideAddContactCard = () => setToggleAddContactCard(false);

  async function getBase64ImageFromUrl(imageUrl) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener("load", function () {
        resolve(reader.result);
      }, false);

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }

  const getBase64String = async (newPhoto: string) => {
    let base64String = ''
    await getBase64ImageFromUrl(newPhoto)
      .then(result => {
        base64String = result.split('base64,')[1]
      }).catch((e) => {
        base64String = ''
      })
    return base64String
  }

  const loadvCard = async (newProfilee: any, isConnect?: boolean) => {
    if (
      newProfilee &&
      newProfilee.contactCard &&
      newProfilee.contactCard.public
    ) {

      const contactData = newProfilee.contactCard;

      // Populates the data onto a vCard (VCF file)
      var vCardsJS = require("vcards-js");
      //create a new vCard
      var vCard = vCardsJS();

      vCard.isOrganization = true; // For iPhone Contact Book
      //set properties
      vCard.firstName =
        contactData.first_name && contactData.first_name.toString();
      vCard.lastName =
        contactData.last_name && contactData.last_name.toString();
      vCard.organization =
        contactData.company && contactData.company.toString();
      if (newProfilee) {
        let newPhoto = ''
        if (newProfilee?.photo?.length > 0) {
          newPhoto = newProfilee.photo
        } else {
          newPhoto = "/logo540.png"
        }
        let base64String = await getBase64String(newPhoto)
        if (base64String.length > 0) {
          var decoded = atob(base64String);
          if ((decoded.length / 1024 / 1024) > 1) {
            base64String = await getBase64String("/logo540.png")
          }
        }
        vCard.photo.embedFromString(base64String, "image/png");
      }

      vCard.email = newProfilee.contactCard.email;

      vCard.workAddress.label = "Full Address";
      vCard.workAddress.street = newProfilee.contactCard.address;

      vCard.url = newProfilee.contactCard.website;

      if (contactData.phones) {
        vCard.workPhone = newProfilee.contactCard.phones.work_phone;
        vCard.cellPhone = newProfilee.contactCard.phones.cell_phone;
        vCard.homePhone = newProfilee.contactCard.phones.home_phone;
        vCard.pagerPhone = newProfilee.contactCard.phones.pager_phone;
      }
      if (contactData.birthday) {
        vCard.birthday = new Date(contactData.birthday);
      }
      vCard.note = contactData.notes && contactData.notes.toString();

      var name = vCard.firstName;

      if (isConnect === true) {
        downloadVcard(vCard.getFormattedString(), name + ".vcf", { ...newProfilee, isTappyUser: true })
      } else {
        setCardContents(vCard.getFormattedString());
        setContactName(name + ".vcf");
        setUploadLoader(false)
      }
    } else {
      setUploadLoader(false)
    }
  };

  const handleShowContactCard = () => {
    setUploadLoader(true)
    axiosApi
      .get(`user/${profile.user_id}/profile/${profile.id}/contact/checkPublic`)
      .then((res: any) => {
        if (res.data && res.data.is_public == true) {
          setToggleAddContactCard(true);
        }
        setUploadLoader(false)
      })
      .catch((err) => {
        setUploadLoader(false)
        notification({
          message: "Contact Card Error, please refresh the webpage.",
          type: "warning",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
          },
        });
      });
  };

  const getSocialLink = (socialItem: any) => {
    //console.log(socialItem);
    let socialName = "";
    switch (socialItem.name) {
      case "Apple Music":
        return socialItem.pivot.username;
      case "Calendly":
        return "https://" + socialItem.pivot.username.replace("https://", "");
      case "Cashapp":
        return (
          "https://cash.app/$" + socialItem.pivot.username.replace("$", "")
        );
      case "Email":
        return "mailto:" + socialItem.pivot.username;
      case "Facebook":
        return "https://www.facebook.com/" + socialItem.pivot.username;
      case "Instagram":
        return "https://www.instagram.com/" + socialItem.pivot.username;
      case "Linkedin":
        return socialItem.pivot.username;
      case "Paypal":
        return "https://www.paypal.me/" + socialItem.pivot.username;
      case "Phone":
        return "tel:" + socialItem.pivot.username;
      case "Snapchat":
        return "https://www.snapchat.com/add/" + socialItem.pivot.username;
      case "Tiktok":
        return "https://tiktok.com/@" + socialItem.pivot.username.toLowerCase();
      case "Spotify Account":
        return socialItem.pivot.username;
      case "Spotify Playlist":
        return socialItem.pivot.website_url;
      case "Triller":
        return "https://" + socialItem.pivot.username.replace("https://", "");
      case "Twitter":
        return "https://twitter.com/" + socialItem.pivot.username;
      case "Soundcloud":
        return socialItem.pivot.username;
      case "Twitch":
        return "https://twitch.tv/" + socialItem.pivot.username;
      case "Venmo":
        return "https://venmo.com/" + socialItem.pivot.username;
      case "Youtube":
        return socialItem.pivot.website_url + '/' + socialItem.pivot.username;
      case "Youtube Video":
        return (
          "https://" + socialItem.pivot.website_url.replace("https://", "")
        );
      case "Website":
        return (
          "https://" + socialItem?.pivot?.website_url?.replace("https://", "")
        );
      default:
        return socialName;
    }
  };

  function fireEvent() {
    let event = {
      action: `add_contact`,
      key: 'contact_btn',
      value: ''
    }
    if (checkSerialNumber(profile.contactCard.username)) {
      event.value = `${params?.username / profile.contactCard.username} contact`
    } else {
      event.value = `${profile.contactCard.username} contact`
    }
    googleAnalyticsCustomEvent(event)
  }

  const reorderSocials = (socials: any, ordered: any) => {
    var orderedSocials = [];

    //console.log(socials);
    //console.log(ordered);
    if (ordered) {
      var socialsSplit = ordered.split(",");
      for (var i = 0; i < socialsSplit.length; i++) {
        for (var j = 0; j < socials.length; j++) {
          if (socials[j].pivot.id == socialsSplit[i]) {
            orderedSocials.push(socials[j]);
            break;
          }
        }
      }
    }
    if (props.state.selectedProfile) {
      props.state.selectedProfile!.socials = orderedSocials;
    }
    // return orderedSocials;
    return socials;
  };

  const socialItems = profile && profile.socials
    ? profile.socials.map((socialItem: any, id: any) => (
      <div className="edit-social-media-profiles" key={id}>
        <ProfileSocial
          imgSrc={getSocialIcon(socialItem.name)}
          name={socialItem.name}
          username={socialItem.pivot.username}
          socialLink={getSocialLink(socialItem)}
          profile={profile}
          button_color={button_color}
        />
      </div>
    ))
    : null;

  const generateTextForSms = (userDetails: any) => {

    let msg = ''
    if (userDetails?.isTappyUser === true) {
      msg = `Hey ${profile?.contactCard?.first_name}! Here is  ${userDetails?.contactCard?.first_name} tappy business card.\n\nThis is their tappy profile with social media and additional contact info->\nwww.tappy.tech/${userDetails?.contactCard?.username}`
    } else if (userDetails?.isReceiver === true) {
      msg = `Hey ${profile?.contactCard?.first_name}! Here is ${userDetails.firstName} contact card.`
    } else {
      msg = `Hey ${userDetails.firstName}! Here is ${profile?.contactCard?.first_name} tappy business card.\n\nThis is their tappy profile with social media and additional contact info->\nwww.tappy.tech/${profile?.contactCard?.username}`
    }
    return msg
  }

  const deleteContactCard = (bucketName, cardName) => {
    const params: any = {
      Bucket: bucketName,
      Key: cardName
    };

    setTimeout(() => {
      deleteFromS3(params, () => { })
    }, 2000);
  }

  const downloadVcard = (cardContent = cardContents, contactCardName = contactName, userDetails: any) => {
    setUploadLoader(true)
    setShowModal(false)
    const bucketName = process.env.REACT_APP_AWS_BUCKET;
    const params = ({
      Bucket: bucketName ? bucketName : '',
      Key: contactCardName,
      Body: cardContent,
      ContentDisposition: `inline; filename="${contactCardName}"`,
      CacheControl: 'no-cache',
      ContentType: 'text/vcard'
    })

    uploadOnS3(params, (status: boolean, progress: number) => {
      if (status) {
        let contactCard = `https://${bucketName}.s3.amazonaws.com/${contactCardName}`;
        if (typeof userDetails !== 'undefined') {
          sendSMSContact(contactCard, userDetails, bucketName, contactCardName)
        } else {
          window.location.replace(contactCard)
          deleteContactCard(bucketName, contactCardName)
          setUploadLoader(false)
        }
      } else if (!status && typeof progress === 'undefined') {
        setUploadLoader(false)
      }
    })
  }
  const findContactNumber = (profile: any) => {
    let receiverNumber = profile?.contactCard?.phones?.cell_phone?.find((item: any) => item?.length > 0)
    receiverNumber = receiverNumber?.length > 0 ? receiverNumber : profile?.contactCard?.phones?.home_phone?.find((item: any) => item?.length > 0)
    receiverNumber = receiverNumber?.length > 0 ? receiverNumber[0] == 0 ? receiverNumber.slice(1, receiverNumber.length) : receiverNumber : ''
    let countryCode = receiverNumber.includes('+') ? '' : '+1'
    return { countryCode, receiverNumber }
  }

  const createVcard = (userDetails: any) => {
    const { receiverNumber, countryCode } = findContactNumber(profile)

    if (userDetails?.firstName?.length > 0 && receiverNumber?.length > 0) {
      var vCardsJS = require("vcards-js");
      var vCard = vCardsJS();
      vCard.isOrganization = true;
      //set properties
      vCard.firstName = userDetails.firstName.toString();
      vCard.lastName = userDetails.lastName.toString();
      vCard.email = userDetails.email;
      vCard.cellPhone = userDetails.phone
      const updatedUserDetails = {
        ...userDetails,
        phone: countryCode + receiverNumber,
        isReceiver: true,
      }
      downloadVcard(vCard.getFormattedString(), `${userDetails.firstName}.vcf`, updatedUserDetails)
    }

  }

  const sendSMSContact = async (url: string, userDetails: any, bucketName: string, contactCardName: string) => {
    let receiverMobileNumber = ''
    if (userDetails?.isTappyUser === true) {
      const { receiverNumber, countryCode } = findContactNumber(profile)
      receiverMobileNumber = countryCode + receiverNumber
    } else {
      receiverMobileNumber = userDetails?.phone
    }
    const data = {
      receiverNumber: receiverMobileNumber,
      bodyMessage: generateTextForSms(userDetails),
      mediaUrl: url
    }
    const response = await axiosApi.post(`user/sendSMS`, data, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`
      }
    });
    setUploadLoader(false)
    if (response?.data?.status == true) {
      setShowModal(true)
      deleteContactCard(bucketName, contactCardName)
      tappyModalRef?.current?.showTappySuccess()
    } else {
      notification({
        message: "Failed to send message.",
        type: "danger",
      });
    }
  }

  const handleSendSMS = async (userDetails: any) => {
    const data = {
      params: {
        userName: userDetails?.email
      }
    }
    
    try {
      const response = await axiosApi.get(`user/details`, data, {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`
        }
      });
      if (response?.data?.data?.length > 0) {
        setUploadLoader(true)
        loadUserProfile(response?.data?.data?.[0]?.username, 'username', true)
      } else {
        createVcard(userDetails)

      }
    } catch (e) {
      createVcard(userDetails)
    }
    downloadVcard(cardContents, contactName, userDetails)
  }

  return (
    <>
      {/* ------------new design----------------- */}
      <TappyConnect
        show={showModal}
        ref={tappyModalRef}
        onHide={() => setShowModal(false)}
        name={profile ? profile.name : null}
        shareContactCard={handleSendSMS}
      />
      {uploadLoader ? <div className="background-upload-loader"><Spinner animation="border" /></div> : null}
      {profileExists && profileExists === true ? (
        <div
          id="view-profile-page"
          className={`edit-profile-page profile-page`}
        >
          {profile?.background_color?.includes(videosFolderName) && (
            <video className="videoConntainer" autoPlay muted loop preload='auto' playsInline >
              <source src={profile?.background_color} type="video/mp4" />
            </video>
          )}
          {profile?.background_color?.includes(imagesFolderName) && (
            <img
              alt="Profile Avatar"
              className="linear-gradient-background"
              src={profile?.background_color}
            />
          )}
          {profile?.background_color?.includes("linear-gradient") || profile?.background_color?.includes("rgba") ? (
            <div
              className="linear-gradient-background"
              style={{
                backgroundImage: profile?.background_color?.includes(
                  "linear-gradient"
                )
                  ? profile?.background_color
                  : "",
                backgroundColor: profile?.background_color,
                backgroundSize: "cover",
                borderRadius: 0,
              }}
            />
          ) : null}
          {profile?.theme === "dark" ? (
            <>
              <div className="profile-header-container">
                <Container className={`profile-container`}>
                  <div className="edit-base-profile">
                    <div className="edit-profile__img">
                      <div
                        className="avatar-preloader"
                        style={{
                          backgroundImage: "url('/images/avatar-loading.png')",
                        }}
                      ></div>
                      {profile &&
                        profile.photo &&
                        profile.photo !== "" &&
                        profile.photo !== "null" ? (
                        <img
                          alt="Profile Avatar"
                          style={{
                            objectFit: "cover",
                            width: "120px",
                            height: "120px",
                            borderRadius: "50%",
                            zIndex: 1000
                          }}
                          src={profile.photo}
                        />
                      ) : (
                        <img
                          alt="Profile Avatar"
                          style={{
                            objectFit: "cover",
                            width: "120px",
                            height: "120px",
                            borderRadius: "50%",
                            zIndex: 999
                          }}
                          src="/images/tappy-avatar.png"
                        />
                      )}
                    </div>

                    <div className="profile-basic-info">
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label
                          className="profile-name"
                          style={{
                            color: profile?.accent_text_color,
                            fontFamily: profile?.font_family,
                          }}
                        >
                          {profile ? profile.name : null}
                        </Form.Label>
                        {profile &&
                          profile.contactCard &&
                          (profile.contactCard.public == true ? (
                            profile.contactCard.pin_active == true ? (
                              <form
                                encType="multipart/form-data"
                                acceptCharset="utf-8"
                              >
                                <input
                                  type="text"
                                  id="vcardName"
                                  name="filename"
                                  style={{ display: "none" }}
                                  value={contactName}
                                ></input>
                                <textarea
                                  id="vcard"
                                  style={{ display: "none" }}
                                  name="vcard"
                                  value={cardContents}
                                  readOnly={true}
                                ></textarea>
                                <button
                                  style={{ display: "none" }}
                                  id="vcard-form"
                                ></button>
                                <button
                                  className="dark-theme-dwn-cotact"
                                  style={{
                                    backdropFilter: 'blur(3px)',
                                    WebkitBackdropFilter: 'blur(3px)',
                                  }}
                                  onClick={(event) => {
                                    event.preventDefault();
                                    handleShowContactCard();
                                    fireEvent()
                                  }}
                                >
                                  {isPremium ? 'Connect' : 'Add to contacts'}
                                </button>
                              </form>
                            ) : (
                              <form
                                encType="multipart/form-data"
                                acceptCharset="utf-8"
                              >
                                <input
                                  type="text"
                                  id="vcardName"
                                  name="filename"
                                  style={{ display: "none" }}
                                  value={contactName}
                                ></input>
                                <textarea
                                  id="vcard"
                                  style={{ display: "none" }}
                                  name="vcard"
                                  value={cardContents}
                                  readOnly={true}
                                ></textarea>
                                <button
                                  className={`dark-theme-dwn-cotact ${profile?.button_shape}`}
                                  style={{
                                    color: profile?.accent_text_color,
                                    backgroundColor: `rgba(${button_color?.r}, ${button_color?.g}, ${button_color?.b}, ${button_color?.a})`,
                                    borderColor: profile?.button_stroke,
                                    fontFamily: profile?.font_family,
                                    backdropFilter: 'blur(3px)',
                                    WebkitBackdropFilter: 'blur(3px)',
                                  }}
                                  id="download-trigger"
                                  onClick={(event) => {
                                    event.preventDefault();
                                    if (isPremium) {
                                      setShowModal(true)
                                    } else {
                                      downloadVcard()
                                    }
                                    fireEvent()
                                  }}
                                >
                                  {isPremium ? 'Connect' : 'Add to contacts'}
                                </button>
                              </form>
                            )
                          ) : (
                            <></>
                          ))}
                      </Form.Group>
                    </div>
                  </div>
                  <Form.Group
                    controlId="formBasicEmail"
                    style={{ padding: "10px 12px" }}
                  >
                    <span
                      className="profile-bio"
                      style={{
                        color: profile?.accent_text_color,
                        fontFamily: profile?.font_family,
                        whiteSpace: 'pre-line'
                      }}
                    >
                      {profile ? profile.bio : null}
                    </span>
                  </Form.Group>
                </Container>
              </div>
              <div className={"social-items__container"}>{socialItems}</div>
              <a
                className={`tappy-upsell show ${windowWidth < divElement ? "hide-tapy-button" : ""
                  } ${profile?.hide_tappy_footer === 1 && "hide-tappy-footer"}`}
                style={{ fontFamily: profile?.font_family }}
                target="__blank"
                href="https://tappycard.com/"
              >
                Click here to buy a tappy
              </a>
            </>
          ) : (
            <div
              className={`selected-theme-${profile?.theme}`}
              style={{ zIndex: 9 }}
            >
              <Container className="edite-base-profile-block">
                <div className="profile-picture-background">
                  <div
                    style={{
                      backgroundImage:
                        profile &&
                          profile.photo &&
                          profile.photo !== "" &&
                          profile.photo !== "null"
                          ? `url(${profile.photo})`
                          : "url('/images/tappy-avatar.png)",
                    }}
                    className="edite-base-proflie-image-inner"
                  />
                </div>
                <Form.Label
                  className="profile-name justify-content-center"
                  style={{
                    color: profile?.accent_text_color,
                    fontFamily: profile?.font_family,
                  }}
                >
                  {profile ? profile.name : null}
                </Form.Label>

                {profile &&
                  profile.contactCard &&
                  (profile.contactCard.public == true ? (
                    profile.contactCard.pin_active == true ? (
                      <form
                        encType="multipart/form-data"
                        className="download-contact-form"
                        acceptCharset="utf-8"
                      >
                        <input
                          type="text"
                          id="vcardName"
                          name="filename"
                          style={{ display: "none" }}
                          value={contactName}
                        ></input>
                        <textarea
                          id="vcard"
                          style={{ display: "none" }}
                          name="vcard"
                          value={cardContents}
                          readOnly={true}
                        ></textarea>
                        <button
                          style={{ display: "none" }}
                          id="vcard-form"
                        ></button>
                        <button
                          className="add-to-contact-btn"
                          style={{
                            color: profile?.accent_text_color,
                            backgroundColor: `rgba(${button_color?.r}, ${button_color?.g}, ${button_color?.b}, ${button_color?.a})`,
                            borderColor: profile?.button_stroke,
                            fontFamily: profile?.font_family,
                            backdropFilter: 'blur(3px)',
                            WebkitBackdropFilter: 'blur(3px)',
                          }}
                          onClick={(event) => {
                            event.preventDefault();
                            handleShowContactCard();
                            fireEvent()
                          }}
                        >
                          {isPremium ? 'Connect' : 'Add to contacts'}
                        </button>
                      </form>
                    ) : (
                      <form
                        encType="text/vcard"
                        className="download-contact-form"
                      >
                        <input
                          type="text"
                          id="vcardName"
                          name="filename"
                          style={{ display: "none" }}
                          value={contactName}
                        ></input>
                        <textarea
                          id="vcard"
                          style={{ display: "none" }}
                          name="vcard"
                          value={cardContents}
                          readOnly={true}
                        ></textarea>
                        <button
                          className={`dark-theme-dwn-cotact ${profile?.button_shape}`}
                          style={{
                            color: profile?.accent_text_color,
                            backgroundColor: `rgba(${button_color?.r}, ${button_color?.g}, ${button_color?.b}, ${button_color?.a})`,
                            borderColor: profile?.button_stroke,
                            fontFamily: profile?.font_family,
                            backdropFilter: 'blur(3px)',
                            WebkitBackdropFilter: 'blur(3px)',
                          }}
                          id="download-trigger"
                          onClick={(event) => {
                            event.preventDefault();
                            if (isPremium) {
                              setShowModal(true)
                            } else {
                              downloadVcard()
                            }
                            fireEvent()
                          }
                          }
                        >
                          {isPremium ? 'Connect' : 'Add to contacts'}
                        </button>
                      </form>
                    )
                  ) : (
                    <></>
                  ))}
                {profile?.bio && (
                  <Form.Group
                    controlId="formBasicEmail"
                    className="pt-4 text-center"
                  >
                    <span
                      className="profile-bio"
                      style={{
                        color: profile?.accent_text_color,
                        fontFamily: profile?.font_family,
                        whiteSpace: 'pre-line'
                      }}
                    >
                      {profile.bio}
                    </span>
                  </Form.Group>
                )}
              </Container>
              <div className={"social-items__container"}>{socialItems}</div>
              <a
                className={`tappy-upsell show ${windowWidth < divElement ? "hide-tapy-button" : ""
                  } ${profile?.hide_tappy_footer === 1 && "hide-tappy-footer"}`}
                style={{ fontFamily: profile?.font_family }}
                target="__blank"
                href="https://tappycard.com/"
              >
                Click here to buy a tappy
              </a>
            </div>
          )}
          <AddContactCard
            onHideAddContactCard={onHideAddContactCard}
            toggleModal={toggleAddContactCard}
            state={props.state}
            isPremium={isPremium}
            setShowModal={setShowModal}
            setState={props.setState}
            profile={profile}
            downloadVcard={downloadVcard}
            setName={setContactName}
            setContact={setCardContents}
          />
        </div>
      ) : (
        <NotFound />
      )}
    </>
  );
};
