// @ts-nocheck
import '../StripeSelectSubscribption/StripeSelectSubscribption.scss'
import './AddPaymentMethod.scss'
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { Button } from 'react-bootstrap';
import { modalSteps, paymentMethods } from '../../../utils/StaticData/staticData';
import { IoArrowBack } from "react-icons/io5";
import { StripeAddressElementOptions, StripeElementsOptions, loadStripe } from '@stripe/stripe-js';
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
  AddressElement,
} from '@stripe/react-stripe-js';
import notification from "../../Notification";
import axios from 'axios';
import { AppContextInterface, useAppData } from '../../../hooks/useAppData';
import { LottieLoading } from '../../LottieLoading/LottieLoading';
import axiosApi from '../../../axiosApi';
import { ConfirmationModal } from '../../ConfirmationModal/ConfirmationModal';
import { MdClose } from "react-icons/md";
import { googleAnalyticsCustomEvent } from '../../../utils/google-analytics';

interface Props {
  handleHistory: (url: string) => void;
  goBack?: () => void;
  params: any;
  steps?: any;
  setSteps?: any;
}

const CheckoutForm = forwardRef((props, ref) => {
  const stripe: any = useStripe();
  const elements: any = useElements();
  const { params, setAnimationLoader, promoCode, handleHistory } = props
  const {
    subscriptionDetails,
  } = useAppData() as AppContextInterface;

  useImperativeHandle(ref, () => ({
    callSubscriptionApi: (cardDetails) => {
      setAnimationLoader(true)
      subcribeTappyPro(cardDetails)
    }
  }))

  const gotoActiveSubscriptionPage = (message, error) => {
    let event = {
      action: `stripe`,
      key: 'subscription_response',
      value: ''
    }
    if (error) {
      event.value = 'Subscription Failed'
    } else {
      event.value = 'Subscription Successful'
    }
    googleAnalyticsCustomEvent(event)
    subscriptionDetails(() => {
      if (typeof error === 'undefined') {
        handleHistory('activeSubscription')
      }
      setAnimationLoader(false)
      notification({
        message: message,
        type: error || "Success",
      });
    })
  }

  const subcribeTappyPro = async (paymentMethod: any) => {
    const { id, card, billing_details } = paymentMethod
    const { last4, funding, country, brand, exp_month, exp_year } = card
    const { name } = billing_details
    try {
      const formData = new FormData()
      formData.append("plan", params?.plan.id)
      formData.append("name", name)
      formData.append("token", id)
      formData.append("brand", brand)
      formData.append("country", country)
      formData.append("exp_month", exp_month)
      formData.append("exp_year", exp_year)
      formData.append("funding", funding)
      if (promoCode?.id) {
        formData.append("promoCodeId", promoCode.id)
      }
      formData.append("last4", last4)
      formData.append("trialDays", process.env.REACT_APP_TRIAL_DAYS)  // add it in env

      axios.post(`${process.env.REACT_APP_PROXY_DEVELOPMENT}/api/user/subscription`, formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
          }
        }).then((response) => {
          gotoActiveSubscriptionPage("Subscription successful")
        }).catch((err) => {
          gotoActiveSubscriptionPage("Something went wrong!", 'danger')
        })
    } catch (e) {
      gotoActiveSubscriptionPage("Something went wrong!", 'danger')
    }
  }

  const addMoreCard = async (paymentMethod) => {
    try {
      const response = await axiosApi.post(`user/add/payment/method`, { card_details: { ...paymentMethod, payment_method: paymentMethod?.id } });
      if (response.status === 200) {
        gotoActiveSubscriptionPage("Card added succesfully")
      } else {
        notification({
          message: "Something went wrong!",
          type: "danger",
        });
        setAnimationLoader(false)
      }
    } catch (e) {
      notification({
        message: "Something went wrong!",
        type: "danger",
      });
      setAnimationLoader(false)
    }
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (elements == null) {
      return;
    }

    // Trigger form validation and wallet collection
    console.log('elements:::', elements);
    if (!stripe || !elements) {
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      return;
    }

    setAnimationLoader(true)

    if (params?.edit) {
      const addressElement = elements.getElement('address');
      await addressElement.getValue()
        .then(async function (result) {
          if (result.complete) {
            const response = await axiosApi.post(`user/add/payment/method/details/update`, { payment_method_id: params?.edit?.id, billing_details: result.value });
            if (response?.data?.status) {
              gotoActiveSubscriptionPage(response?.data?.message)
            } else {
              gotoActiveSubscriptionPage('Something went wrong!', 'danger')
            }
          }
        }).catch((err) => {
          gotoActiveSubscriptionPage('Something went wrong!', 'danger')
        })

      return false
    }

    const { paymentMethod, error } = await stripe.createPaymentMethod({
      elements,
    });
    console.log('paymentMethod:::', paymentMethod);

    if (error) {
      setAnimationLoader(false)
      console.error(error.message);
    } else {
      if (params?.add) {
        addMoreCard(paymentMethod)
      }
      else {
        subcribeTappyPro(paymentMethod)
      }
    }

  };

  const options: StripeAddressElementOptions = {
    mode: 'billing',
    defaultValues: { ...params?.edit?.billing_details }
  }

  return (
    <form onSubmit={handleSubmit}>
      {!params?.edit && <PaymentElement />}
      <AddressElement options={options} />
      <div className='stripe-btn-container' id='card-paymentElement'>
        <Button className="react-btn" disabled={!stripe || !elements} type='submit' variant="primary" size='lg' >{params?.add ? 'Add payment method' : params?.edit ? 'Update billing information' : 'Start your free 14 day trial'}</Button>
      </div>
    </form>
  );
});

export const AddPaymentMethodComp: React.FC<Props> = (props) => {

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>(props?.params?.paymentMethod || 'Card');
  const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);
  const { params, handleHistory } = props
  const [animationLoader, setAnimationLoader] = useState<boolean>(false);
  const [showPromoModal, setPromoModal] = useState<boolean>(false);
  const [promoCode, setPromoCode] = useState<any>({});
  const [promoText, setPromoText] = useState<boolean>('');
  const checkoutApiRef = useRef<any>(null)

  useEffect(() => {
    if (!params?.edit) {
      const event = {
        action: `stripe`,
        value: 'On Subscription Page',
        key: 'stripe_subscription_page'
      }
      googleAnalyticsCustomEvent(event)
    }
    if (props?.params?.cardDetails?.id) {
      checkoutApiRef?.current?.callSubscriptionApi(props?.params?.cardDetails)
    }
  }, [])

  const options: StripeElementsOptions = {
    mode: 'subscription',
    amount: 1099,
    currency: 'usd',
    paymentMethodCreation: 'manual',
    payment_method_types: ["card"],
    appearance: {
      theme: 'flat',
      variables: {
        colorBackground: '#31405E',
        borderRadius: '10px',
        colorText: 'white',
        colorTextPlaceholder: '#6F86A9',
      }
    }
  };

  const checkPromoValidation = async () => {
    try {
      if (promoText?.length > 0) {
        setAnimationLoader(true)
        setPromoModal(false)
        const response = await axiosApi.get(`user/verify/promo/code/${promoText}`);
        console.log('response:::', response)
        if (response.status === 200) {
          setPromoCode(response.data.promocode)
          notification({
            message: 'Promo code applied successfully',
            type: "success",
          });
        } else {
          notification({
            message: "Something went wrong!",
            type: "danger",
          });
          setPromoCode({})
        }
        setAnimationLoader(false)
        setPromoText('')
      } else {
        setAnimationLoader(false)
        setPromoCode({})
      }
    } catch (e) {
      setPromoText('')
      setPromoCode({})
      setPromoModal(false)
      setAnimationLoader(false)
      notification({
        message: e?.response?.data?.message || "Something went wrong!",
        type: "danger",
      });
    }
  }

  const calculateOffer = useMemo(() => {
    let text = ''
    if (promoCode?.coupon?.percent_off) {
      text = promoCode?.coupon?.percent_off + '%'
    } else if (promoCode?.coupon?.amount_off) {
      text = (promoCode?.coupon?.amount_off / 100)?.toFixed(2) + '$'
    }
    text += ' off applied on the selected subscription plan.'
    return text
  }, [promoCode])

  return (
    <div className={`subscribption ${props?.steps?.type ? '' : 'subscribption-main'}`}>
      <div className='subscibption-container'>
        <div className='back-btn-container'>
          {props?.steps?.type && <IoArrowBack size={21} className='back-btn' onClick={() => props?.setSteps({ type: modalSteps[1], param: '' })} />}
          <p className='normal-text bold'>{params?.edit ? 'Edit billing information' : 'Add payment method'}</p>
        </div>
        <div className='stripe-payment-amount-container'>
          {promoCode?.id ?
            <div className='stripe-payment-offer-container'>
              <div style={{ width: '95%' }}>
                <span className='stripe-promo-text normalStyle'>{calculateOffer}</span>
              </div>
              <div className='remove-promo-btn' onClick={() => setPromoCode({})}>
                <MdClose size={17} />
              </div>
            </div>
            :
            <>
              {!params?.edit && !params?.add && <>
                <span className='stripe-promo-text'>Have a promo code?</span>
                <div onClick={() => setPromoModal(true)}>
                  <span className='stripe-promo-text left underline'>Click here</span>
                </div>
              </>}
            </>
          }
        </div>
        {!params?.edit &&
          <div className='displayFlex last space_between'>
            {paymentMethods.map((item) =>
              <div className={`displayFlex column payment-method-inner-container ${selectedPaymentMethod === item?.name ? 'selected' : null}`} onClick={() => setSelectedPaymentMethod(item?.name !== 'Card' ? 'Apple Pay' : 'Card')}>
                <div className='icon-main-container'>
                  {item.icon}
                </div>
                <p className='normal-text'>{item.name}</p>
              </div>
            )}
          </div>
        }
      </div>
      <div className='apple_pay_method' style={{ display: selectedPaymentMethod === 'Card' ? 'none' : 'block' }}>
        <p className='normal-text medium alignText'>Apple pay selected</p>
        <div className='apple-btn-container'>
          <div className='displayFlex column space_between apple-btn-inner-container'>
            <Button className="apple-btn" variant="primary" size='lg'>Add Apple Pay</Button>
            <p className='normal-text medium alignText'>Another step will appear to securely submit your payment method</p>
          </div>
        </div>
        <Button className="go-back-btn" variant="primary" size='lg' onClick={() => props?.goBack()}>Go back</Button>
        <p className='apple-footer'>By continuing, you allow Tappy, LLC to charge your Apple Pay for this payment and future payments in accordance with their terms. You can review important information on Tappy's Terms of Service and Privacy Policy pages.</p>
      </div>
      <div style={{ display: selectedPaymentMethod !== 'Card' ? 'none' : 'block' }}>
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm
            params={params}
            setAnimationLoader={setAnimationLoader}
            handleHistory={handleHistory}
            ref={checkoutApiRef}
            promoCode={promoCode}
          />
        </Elements>
      </div>
      {animationLoader ? <LottieLoading /> : <></>}
      <ConfirmationModal
        showModal={showPromoModal}
        hideModal={() => setPromoModal(!showPromoModal)}
        deleteCard={() => checkPromoValidation()}
        subHeading={'You will be charged US $53.01 for the annual plan.'}
        leftBtn={'Cancel'}
        showInput={true}
        onChangeText={setPromoText}
        promoId={promoText}
        rightBtn={'Apply'}
        heading={'Please enter promo code'}
        color={'primary'}
      />
    </div >
  );
}