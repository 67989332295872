import "./AddContactCard.scss";
import React, { useEffect, useState } from "react";
import {
  Form,
  Modal
} from "react-bootstrap";
import axiosApi from "../../axiosApi";
import notification from "../Notification";
import Button from '../Button';

interface ContactCard {
  id: number;
  profile_id: number;
  first_name: string;
  last_name: string;
  company: string;
  phones: {
    cell_phone: string[],
    pager_phone: string[],
    work_phone: string[],
    home_phone: string[]
  };
  email: string;
  website: string;
  address: string;
  birthday: string;
  notes: string;
  pin_active: boolean;
  security_pin: string;
}

interface Pivot {
  profile_id: number,
  social_id: number;
  username: string;
}

interface Social {
  id: number;
  name: string;
  pivot: Pivot;
}

interface Profile {
  id: number;
  user_id: number;
  name: string;
  bio: string;
  photo: string;
  created_at: string;
  updated_at: string;
  socials: Social[] | null;
  pin_active: boolean;
  contactCard: ContactCard;
}

interface State {
  session: number;
  userData: {};
  selectedProfile: Profile | null;
}

interface Props {
  toggleModal: boolean;
  onHideAddContactCard: () => void;
  state: State;
  isPremium: boolean
  setState: React.Dispatch<React.SetStateAction<any>>;
  profile: Profile | null;
  setName: (name: string) => void;
  setContact: (content: any) => void;
  setShowModal: (show: any) => void;
  downloadVcard: () => void;
}


export const AddContactCard: React.FC<Props> = (props) => {

  const { isPremium, setShowModal,downloadVcard } = props

  const [contactCardCode, setContactCardCode] = useState("");

  useEffect(()=>{
    if(props.toggleModal){
      setContactCardCode("")
    }
  },[props.toggleModal])

  const handleAddContact = async () => {
    const data = {
      security_pin: contactCardCode
    }

    if (props.profile) {
      try {
        const response = await axiosApi.post(`user/${props.profile.user_id}/profile/${props.profile.id}/contact/verify`, data);

        if (response.data.pin_verified === "true") {
          props.onHideAddContactCard();
          if (isPremium) {
            setShowModal(true)
          } else {
            downloadVcard()
          }
        } else {
          notification({
            message: "Invliad Pin",
            type: "warning",
            insert: "bottom",
            container: "bottom-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2500,
            }
          });
        }
      } catch (err) {
        notification({
          message: "Network Error",
          type: "warning",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2500,
          }
        });
      }
    }
  }

  return (

    <Modal
      show={props.toggleModal}
      onHide={() => props.onHideAddContactCard()}
      className="add-contact-card-modal">
      <Modal.Header closeButton>
      </Modal.Header>
      <div className="add-contact-card__modal">
        <Form className="add-contact-card__modal__form">
          <Form.Label
            className="contact-card-secure-header">
            {props.profile ? props.profile.name : null}'s contact card is secured</Form.Label>
          <span className="contact-card-digit-prompt">enter the security pin to add this contact:</span>
          <Form.Control
            type="email"
            value={contactCardCode}
            onChange={(event) => {
              setContactCardCode(event.target.value);
            }} />
          <Button
            onClick={(event) => {
              event.preventDefault();
              handleAddContact();
            }}
          >Enter</Button>
        </Form>
      </div>
    </Modal>
  );
}
