import "./Header.scss";
import React, { useState } from "react";
import {
  Navbar,
  Nav,
  Modal
} from 'react-bootstrap';
import { Link, useLocation } from "react-router-dom";
import HamburgerMenu from "react-hamburger-menu";
import { NavLink } from "../NavLink/NavLink";
import notification from "../Notification";
import { Helmet } from "react-helmet";
import { useAppData, AppContextInterface } from "../../hooks/useAppData";
import Button from '../Button';
import { headerSocialIcons } from "../../utils/StaticData/staticData";
import { AddNewTappy } from "../AddNewTappy/AddNewTappy";

interface State {
  session: number;
  userData: any;
}

interface Props {
  title: string;
  handleLogout: () => void;
  handleHistory: (url: string) => void;
  state: State;
}



export const Header: React.FC<Props> = (props) => {
  const {
    accentColor,
    subscriptionData,
    state,
    setState,
    setShowQR,
    showQR,
    isActiveSubscription
  } = useAppData() as AppContextInterface;
  const [toggled, setToggled] = useState(false);
  const [toggleAddNewTappy, setToggleAddNewTappy] = useState(false);
  const location = useLocation()

  let globalCustomCSSWithTheme;
  globalCustomCSSWithTheme = `.openQRCode .modal-content {background-image:  linear-gradient(#272727, #272727), linear-gradient(to bottom, ${accentColor}, ${accentColor});  } .openNavBar .modal-content {background-image:  linear-gradient(#272727, #272727), linear-gradient(to bottom, #567df4, ${accentColor});  }`;

  const baseUrl = process.env.REACT_APP_BASE_URL
  const isProfileScreen = (location.pathname.includes("/profile") || !location.key) ? true : false
  const tappyHeaderLogo = (isProfileScreen && isActiveSubscription.active && state?.selectedProfile?.header_logo?.length > 0) ? state?.selectedProfile?.header_logo : "/images/tappyDefaultLogo.png";

  const copyProfileURL = () => {
    if (props.state.userData && props.state.userData.username) {
      navigator?.clipboard?.writeText(`${baseUrl}/` + props.state.userData.username);

      notification({
        message: "Profile URL copied to clipboard!",
        type: "success",
        insert: "bottom",
        container: "bottom-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
        }
      });
    } else {
      notification({
        message: "Fetching Profile Username Failed",
        type: "warning",
        insert: "bottom",
        container: "bottom-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
        }
      });
    }

  }

  function handleModal(path: string) {
    if (path === "logout") {
      props.handleLogout()
    } else if (path.includes('https')) {
      window.open(path, "_self")
    } else {
      props.handleHistory(path)
    }
    setToggled(false)
  }

  function handleManageTappy() {
    if (props.state.session === -1) {
      setToggleAddNewTappy(false)
    } else {
      setToggled(false)
      setToggleAddNewTappy(true)
    }
  }

  return (
    <>
      <Helmet style={[{ cssText: `${globalCustomCSSWithTheme}` }]} />

      <Navbar className="main-header" style={{ backgroundColor: "rgba(38, 38, 40, 1)" }} collapseOnSelect expand="lg" variant="dark">
        <div className="navbar-toggle">
          <HamburgerMenu
            isOpen={toggled}
            menuClicked={() => setToggled(!toggled)}
            width={30}
            height={20}
            strokeWidth={2}
            rotate={0}
            color="#ffffff"
            borderRadius={0}
            animationDuration={0.5}
          />
        </div>
        <Navbar.Brand href={props.state.session === -1 ? "/" : "/dashboard"}>
          <img src={tappyHeaderLogo} alt="tappyLogo" style={{ height: "100%" }} />
        </Navbar.Brand>
        {props.state.session === -1
          ? <Nav.Link as={Button} onClick={() => props.handleHistory("/login")}>Sign In</Nav.Link>
          : window.location.pathname.includes("/dashboard") ? null : <Nav.Link id="share-button" className={"f"} as={Button} onClick={() => setShowQR(true)} style={{ borderColor: "#ffffff", color: '#ffffff' }}>Share Profile</Nav.Link>
        }
      </Navbar>

      <div className={`openQRCode ${showQR ? "show" : "hidden"}`}>
        <div className="modal-content">
          <Modal.Header>
            <a href={props.state.session === -1 ? "/" : "/dashboard"}>
              <img src="/images/tappyDefaultLogo.png" alt="tappyLogo" style={{ height: "100%" }} />
            </a>
            <button type="button" className="close" onClick={() => setShowQR(false)}>
              <span aria-hidden="true">×</span>
            </button>
          </Modal.Header>
          <Modal.Body >
            <Link to={props.state.session === -1 ? "/" : "/dashboard"} style={{ height: "100%" }}>
              <img src={`https://api.qrserver.com/v1/create-qr-code/?size=250x250&data=https://${process.env.REACT_APP_BASE_URL}/${props.state.userData.username}`} alt="QR Code" style={{ height: "100%" }} />
            </Link>
            <h2>{props.state.userData && props.state.userData.username}</h2>
            <Nav.Link id="link-button" as={Button} onClick={() => copyProfileURL()}>{baseUrl}/{props.state.userData && props.state.userData.username}</Nav.Link>
          </Modal.Body>
        </div>
      </div>
      <Modal
        show={toggled}
        onHide={() => setToggled(!toggled)}
        className="openNavBar">
        <Modal.Header style={{ height: "70px" }} closeButton>
          <Link to={props.state.session === -1 ? "/" : "/dashboard"} style={{ height: "100%" }} onClick={() => setToggled(false)}>
            <img src="/images/tappyDefaultLogo.png" alt="tappyLogo" style={{ height: "100%" }} />
          </Link>
        </Modal.Header>
        <Modal.Body>
          {props.state.session !== -1 && (
            <>
              <NavLink
                label="Home"
                destination="/dashboard"
                handleHistory={props.handleHistory}
                toggleHeader={setToggled}
              />

              <NavLink
                label="Account Settings"
                destination="/accountSettings"
                handleHistory={props.handleHistory}
                toggleHeader={setToggled}
              />

              <NavLink
                label="Edit Profile"
                destination="/editProfile"
                handleHistory={props.handleHistory}
                toggleHeader={setToggled}
              />
            </>
          )}
          <NavLink
            label="tappy pro"
            destination={subscriptionData?.subscriptions?.data ? "/activeSubscription" : "/stripe"}
            handleHistory={props.handleHistory}
            toggleHeader={setToggled}
          />
          <Nav.Link onClick={handleManageTappy}>
            Manage tappy products
          </Nav.Link>

        </Modal.Body>
        
        <Modal.Body>
          {props.state.session === -1
            ? <Nav.Link onClick={() => handleModal("/login")}>Sign In</Nav.Link>
            : <Nav.Link className="logout-btn" onClick={() => handleModal("logout")}>Log Out</Nav.Link>
          }
          <div className="shop-tappy-footer-border" />

          <Button className="shop-tappy-btn" onClick={() => handleModal("https://tappycard.com")}>Shop tappy products</Button>
          <Button className="shop-tappy-btn get" onClick={() => handleModal("https://tappycard.com/teams")}>Get tappy for teams</Button>

          <div onClick={() => handleModal("https://tappycard.com/contact")}>
            <p className="shop-tappy-footer support" >Customer Support</p>
          </div>

          <div onClick={() => handleModal('https://tappycard.com/collab')}>
            <p className="shop-tappy-footer" onClick={() => handleModal('https://tappycard.com/collab')}>Join the tappy affiliate program</p>
          </div>
          <div className="shop-tappy-footer-social-icons-container">
            {headerSocialIcons.map((item: any, index: number) =>
              <div onClick={() => handleModal(item.url)} key={index}>
                {item.icon}
              </div>
            )}
          </div>

        </Modal.Body>
      </Modal >
      <AddNewTappy onHideModal={() => setToggleAddNewTappy(false)} showModal={toggleAddNewTappy} state={state} setState={setState} serial={''} />
    </>

  )
}