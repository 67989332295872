import "./EditContactCard.scss";
import React, { useState } from "react";
import { Modal, Form, InputGroup } from "react-bootstrap";
import { ContactCardInformationField } from "../ContactCardInformationField/ContactCardInformationField";
import { AddInformationField } from "../AddInformationField/AddInformationField";
import axiosApi from "../../axiosApi";
import { Error } from "../Error/Error";
import DatePicker from "react-datepicker";
import 'react-notifications-component/dist/theme.css';
import notification from "../Notification";
import { useEffect } from "react";
import { LottieLoading } from "../LottieLoading/LottieLoading";
import {Helmet} from "react-helmet";
import {useAppData, AppContextInterface} from "../../hooks/useAppData";
import Button from '../Button';

interface ContactCard {
  id: number;
  profile_id: number;
  first_name: string;
  last_name: string;
  company: string;
  phones: {
    cell_phone: string[],
    pager_phone: string[],
    work_phone: string[],
    home_phone: string[]
  };
  email: string;
  website: string;
  address: string;
  birthday: string;
  notes: string;
  security_pin: string;
  pin_active: any;
}

interface Pivot {
  profile_id: number,
  social_id: number;
  username: string;
}

interface Social {
  id: number;
  name: string;
  pivot: Pivot;
  showing: boolean;
}


interface Profile {
  id: number;
  user_id: number;
  name: string;
  bio: string;
  photo: string;
  created_at: string;
  updated_at: string;
  social_order: string;
  socials: Social[] | null;
  pin_active: any;
  contactCard: ContactCard;
}

interface State {
  session: number;
  userData: any;
  profiles: Profile[] | null;
  socials: any;
  selectedProfile: Profile | null;
}

interface Props {
  showModal: boolean;
  onHideModal: () => void;
  state: State;
}

export const EditContactCard: React.FC<Props> = (props) => {
  const { accentColor } = useAppData() as AppContextInterface;
  let globalCustomCSSWithTheme;
  globalCustomCSSWithTheme = `.edit-contact-card .modal-dialog {border: 3px  solid ${accentColor}}`;

  //Contact Card Variables
  const [firstName, setFirstName] = useState(
    props.state.selectedProfile && props.state.selectedProfile.contactCard && props.state.selectedProfile.contactCard.first_name
    ? props.state.selectedProfile.contactCard.first_name
    : (props.state.userData && props.state.userData.first_name ? props.state.userData.first_name : "")
  );
  const [showFirstName, setShowFirstName] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const [lastName, setLastName] = useState(
    props.state.selectedProfile && props.state.selectedProfile.contactCard && props.state.selectedProfile.contactCard.last_name
    ? props.state.selectedProfile.contactCard.last_name
    : (props.state.userData && props.state.userData.last_name ? props.state.userData.last_name : "")
  );
  const [showLastName, setShowLastName] = useState(true);

  const [company, setCompany] = useState(props.state.selectedProfile ? props.state.selectedProfile.contactCard.company : "");
  const [showCompany, setShowCompany] = useState(props.state.selectedProfile && props.state.selectedProfile.contactCard.company ? true : false);

  const [cellNumber, setCellNumber] = useState(props.state.selectedProfile && props.state.selectedProfile.contactCard.phones ? props.state.selectedProfile.contactCard.phones.cell_phone : []);
  const [workNumber, setWorkNumber] = useState(props.state.selectedProfile && props.state.selectedProfile.contactCard.phones ? props.state.selectedProfile.contactCard.phones.work_phone : []);
  const [pagerNumber, setPagerNumber] = useState(props.state.selectedProfile && props.state.selectedProfile.contactCard.phones ? props.state.selectedProfile.contactCard.phones.pager_phone : []);
  const [homeNumber, setHomeNumber] = useState(props.state.selectedProfile && props.state.selectedProfile.contactCard.phones ? props.state.selectedProfile.contactCard.phones.home_phone : []);

  const [email, setEmail] = useState(
    props.state.selectedProfile && props.state.selectedProfile.contactCard && props.state.selectedProfile.contactCard.email
    ? props.state.selectedProfile.contactCard.email
    : (props.state.userData && props.state.userData.email ? props.state.userData.email : "")
  );
  const [showEmail, setShowEmail] = useState(true);

  const [website, setWebsite] = useState(props.state.selectedProfile ? props.state.selectedProfile.contactCard.website : "");
  const [showWebsite, setShowWebsite] = useState(props.state.selectedProfile && props.state.selectedProfile.contactCard.website ? true : false);

  const [address, setAddress] = useState(props.state.selectedProfile ? props.state.selectedProfile.contactCard.address : "");
  const [showAddress, setShowAddress] = useState(props.state.selectedProfile && props.state.selectedProfile.contactCard.address ? true : false);

  const [birthday, setBirthday] = useState(props.state.selectedProfile && props.state.selectedProfile.contactCard && props.state.selectedProfile.contactCard.birthday ? new Date(props.state.selectedProfile.contactCard.birthday) : null);
  const [showBirthday, setShowBirthday] = useState(props.state.selectedProfile && props.state.selectedProfile.contactCard.birthday ? true : false);

  const [notes, setNotes] = useState(props.state.selectedProfile ? props.state.selectedProfile.contactCard.notes : "");
  const [showNotes, setShowNotes] = useState(props.state.selectedProfile && props.state.selectedProfile.contactCard.notes ? true : false);

  const [securityPin, setSecurityPin] = useState(props.state.selectedProfile ? props.state.selectedProfile.contactCard.security_pin : "");
  const [pinActive, setPinActive] = useState(props.state.selectedProfile ? props.state.selectedProfile.contactCard.pin_active : false);

  const [toggleInformationFieldModal, setToggleInformationFieldModal] = useState(false);
  const [animationLoader, setAnimationLoader] = useState(false);

  const [error, setError] = useState({
    active: false,
    message: ""
  })

  

  useEffect(() => {
    if(securityPin == null || securityPin.toString() === "") {
      setPinActive(false);
    }
  }, [securityPin])

  const addCellNumber = (newNumber: any) => {
    let oldNumbers: any = cellNumber;
    oldNumbers.push(newNumber);
    setCellNumber(oldNumbers);
  };

  const addWorkNumber = (newNumber: any) => {
    let oldNumbers: any = workNumber;
    oldNumbers.push(newNumber);
    setWorkNumber(oldNumbers);
  };

  const addHomeNumber = (newNumber: any) => {
    let oldNumbers: any = homeNumber;
    oldNumbers.push(newNumber);
    setHomeNumber(oldNumbers);
  };

  const removeCellNumber = (index: number) => {
    let oldNumber: string[] = cellNumber;
    oldNumber.splice(index, 1);
    setCellNumber(oldNumber);
  };

  const removeWorkNumber = (index: number) => {
    let oldNumber: string[] = workNumber;
    oldNumber.splice(index, 1);
    setWorkNumber(oldNumber);
  };

  const removeHomeNumber = (index: number) => {
    let oldNumber: string[] = homeNumber;
    oldNumber.splice(index, 1);
    setHomeNumber(oldNumber);
  };

  const removePagerNumber = (index: number) => {
    let oldNumber: string[] = pagerNumber;
    oldNumber.splice(index, 1);
    setPagerNumber(oldNumber);
  };

  const togglePassword = (e: any) => {
    const passwordField = e.target.parentElement.parentElement.firstChild;
    setShowPassword(!showPassword);
    passwordField.type = showPassword ? "text" : "password";
  }

  //forceUpdate hook
  function useForceUpdate(){
    const [, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
  }
  const forceUpdate = useForceUpdate();

  const formatDate = (date: Date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }

  const handleSaveContactCard = async () => {
    setAnimationLoader(true);

    const accessToken = localStorage.getItem('accessToken');

    if (accessToken && props.state.selectedProfile) {
      let phones: any = {};
      if(cellNumber){
        phones.cell_phone = cellNumber;
      }
      if(workNumber){
        phones.work_phone = workNumber;
        setWorkNumber(workNumber);
      }
      if(pagerNumber){
        phones.pager_phone = pagerNumber;
      }
      if(homeNumber){
        phones.home_phone = homeNumber;
      }

      let formatedBirthday = (showBirthday && birthday !== null) ? formatDate(birthday) : null;


      const data = {
        first_name: firstName,
        last_name: lastName,
        company,
        phones: JSON.parse(JSON.stringify(phones)),
        email,
        website,
        address,
        birthday: formatedBirthday,
        notes,
        security_pin: securityPin ? securityPin : null,
        pin_active: pinActive
      };
      

      try{
        const response = await axiosApi.post(`user/${props.state.session}/profile/${props.state.selectedProfile.id}/contact`, data, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });


        if (response.data.error) {
          setError({
            active: true,
            message: response.data.error
          })
        } else {
          props.state.selectedProfile.contactCard = response.data;
          notification({
            message: "Contact Card Successfully Updated",
            type: "success",
            insert: "bottom",
            container: "bottom-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
            }
          });
          setAnimationLoader(false);

          setTimeout(() => {
            props.onHideModal();
          }, 1000);
        }

      } catch (err: any) {
        setError({
          active: true,
          message: err.response.data
        })
      }
      

    }
  }

  return (
    !animationLoader ?(
      <>
       <Helmet style={[{ cssText: `${globalCustomCSSWithTheme}` }]} />
    <Modal 
      onHide={() => props.onHideModal()}
      show={props.showModal}
      className="edit-contact-card">
      <Modal.Header className="contact-card-model-header" closeButton></Modal.Header>
      <div className="edit-contact-card-content">
        {error.active && (
          <Error error={error} />
        )}
        <Form className="edit-contact-card__form">
          <span className="edit-contact-card__header">Edit your tappy contact card</span>
          <Button 
            className="add-information-field__btn"
            onClick={(event) => {
              event.preventDefault();
              setToggleInformationFieldModal(true);
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="62" height="59" viewBox="0 0 62 59" fill="none">
              <path d="M30.8798 0.422363C14.0769 0.422363 0.452637 13.4773 0.452637 29.5779C0.452637 45.6786 14.0769 58.7335 30.8798 58.7335C47.6827 58.7335 61.307 45.6786 61.307 29.5779C61.307 13.4773 47.6827 0.422363 30.8798 0.422363ZM43.92 31.1398C43.92 31.4262 43.6755 31.6605 43.3767 31.6605H33.0532V41.5525C33.0532 41.8389 32.8087 42.0732 32.5098 42.0732H29.2498C28.9509 42.0732 28.7064 41.8389 28.7064 41.5525V31.6605H18.3829C18.0841 31.6605 17.8396 31.4262 17.8396 31.1398V28.016C17.8396 27.7297 18.0841 27.4954 18.3829 27.4954H28.7064V17.6033C28.7064 17.317 28.9509 17.0827 29.2498 17.0827H32.5098C32.8087 17.0827 33.0532 17.317 33.0532 17.6033V27.4954H43.3767C43.6755 27.4954 43.92 27.7297 43.92 28.016V31.1398Z" fill="#ffffff"/>
            </svg>
            <span className="add-block-dropdown"> Add Information Field</span>
          </Button>
          
          <ContactCardInformationField 
            label="First name:"
            value={firstName}
            show={showFirstName}
            onChange={setFirstName}
            onHide={() => console.log('First Name is mandatory')}
          />
          <ContactCardInformationField 
            label="Last name:"
            value={lastName}
            show={showLastName}
            onChange={setLastName}
            onHide={() => console.log('Last Name is mandatory')}
          />
          <ContactCardInformationField 
            label="Email:"
            value={email}
            show={showEmail}
            onChange={setEmail}
            onHide={() => console.log('Email is mandatory')}
          />
          {cellNumber && cellNumber.length > 0 && cellNumber.map((item, index) => {
            return(
            <ContactCardInformationField 
              label="Cell Number:"
              value={item}
              key={index}
              show={true}
              onChange={(value) => {
                let numbers = [...cellNumber];
                numbers[index] = value;
                setCellNumber(numbers);
              }}
              onHide={() => {
                removeCellNumber(index)
                forceUpdate()
              }}
            />)
          })}
          {workNumber && workNumber.length > 0 && workNumber.map((item, index) => {
            return(
            <ContactCardInformationField 
              label="Work Number:"
              value={item}
              key={index}
              show={true}
              onChange={(value) => {
                let numbers = [...workNumber];
                numbers[index] = value;
                setWorkNumber(numbers);
              }}
              onHide={() => {
                removeWorkNumber(index)
                forceUpdate()
              }}
            />)
          })}
          {pagerNumber && pagerNumber.length > 0 && pagerNumber.map((item, index) => {
            return(
            <ContactCardInformationField 
              label="Pager Number:"
              value={item}
              key={index}
              show={true}
              onChange={(value) => {
                let numbers = [...pagerNumber];
                numbers[index] = value;
                setPagerNumber(numbers);
              }}
              onHide={() => {
                removePagerNumber(index)
                forceUpdate()
              }}
            />)
          })}
          {homeNumber && homeNumber.length > 0 && homeNumber.map((item, index) => {
            return(
            <ContactCardInformationField 
              label="Home Number:"
              value={item}
              key={index}
              show={true}
              onChange={(value) => {
                let numbers = [...homeNumber];
                numbers[index] = value;
                setHomeNumber(numbers);
              }}
              onHide={() => {
                removeHomeNumber(index)
                forceUpdate()
              }}
            />)
          })}
          <ContactCardInformationField 
            label="Company:"
            value={company}
            show={showCompany}
            onChange={setCompany}
            onHide={() => {
              setShowCompany(false)
              setCompany("");
            }}
          />
          <ContactCardInformationField 
            label="Website:"
            value={website}
            show={showWebsite}
            onChange={setWebsite}
            onHide={() => {
              setShowWebsite(false)
              setWebsite("");
            }}
          />
          <ContactCardInformationField 
            label="Address:"
            value={address}
            show={showAddress}
            onChange={setAddress}
            onHide={() => {
              setShowAddress(false)
              setAddress("");
            }}
          />

          <Form.Group>
            {showBirthday && (
              <>
                <Form.Label>Birthday: </Form.Label>
                <div className="info-field-wrapper">
                  <DatePicker
                    showMonthDropdown
                    className="date-picker"
                    showYearDropdown
                    selected={birthday} onChange={(birthday: Date | null) => setBirthday(birthday)}
                  />
                  <svg onClick={() => setShowBirthday(false)} className="remove-field" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M7.5 0C3.3525 0 0 3.3525 0 7.5C0 11.6475 3.3525 15 7.5 15C11.6475 15 15 11.6475 15 7.5C15 3.3525 11.6475 0 7.5 0ZM11.25 10.1925L10.1925 11.25L7.5 8.5575L4.8075 11.25L3.75 10.1925L6.4425 7.5L3.75 4.8075L4.8075 3.75L7.5 6.4425L10.1925 3.75L11.25 4.8075L8.5575 7.5L11.25 10.1925Z" fill="white" fillOpacity="0.6"/>
                  </svg>
                </div>
              </>
            )}
          </Form.Group>
          
          <ContactCardInformationField 
            label="Notes:"
            value={notes}
            show={showNotes}
            onChange={setNotes}
            onHide={() => {
              setShowNotes(false)
              setNotes("");
            }}
          />
          <Form.Group className="edit-contact-card-security">
            <Form.Label className="security-field">Security <span className="security-optional">Optional</span></Form.Label>
            <span className="secure-contact-card">Secure your contact card with a 4-6 digit pin</span>
            <div className="security-pin-container">
              <div className="optional-security-input">
                <input 
                  type={`${showPassword ? "text" : "password"}`}
                  className="form-control"
                  value={securityPin}
                  onInput = {(event:React.ChangeEvent<any>) =>{
                    event.target.value = event.target.value.toString().length > 255 ? event.target.value.slice(0,255) : event.target.value;
                  }}
                  onChange={(event) => {
                    if(event.target.value.toString().length < 4){
                      setPinActive(false);
                    }
                    setSecurityPin(event.target.value);
                  }}
                />
                <InputGroup.Append onClick={(event: any) => {togglePassword(event)}}>
                  {showPassword ? <img src="/images/eye-off.svg" alt="passwordHidden" /> : <img src="/images/eye-on.svg" alt="passwordShown"/>}
                </InputGroup.Append>
              </div>
                <div className="toggle-container">
                  {(pinActive == true) ? 
                  <span className="active switch-toggle-lable" style={{ width: "50px", textAlign: "center"}}>active</span>
                  : <span className="switch-toggle-lable" >deactive</span>
                  }
                  <div className="toggleActive">
                    <input type="checkbox" id="toggleActive" className="checkbox"
                      checked={pinActive == true}
                      onChange={() => { setPinActive(!pinActive) }}
                      disabled={securityPin == null || securityPin.length < 4}
                    />
                    <label htmlFor="toggleActive" className="switch"></label>
                  </div>

                </div>
              </div>
          </Form.Group>
        </Form>
      </div>
      <div className="edit-contact-card-btn__wrapper">
        <Button
        onClick={(event) => {
          event.preventDefault();
          handleSaveContactCard();
        }}
        className="save-contact-card-btn"
        disabled = {securityPin ? securityPin.toString().length > 0 && securityPin.toString().length < 4 : false}
        >Save contact card</Button>
        <Button className="cancle-contact-card-btn" onClick={() => props.onHideModal()} variant={'danger'} danger>Cancel</Button>
      </div>
    <AddInformationField
      showModal={toggleInformationFieldModal}
      onHideModal={() => {
        setToggleInformationFieldModal(false)
      }}
      toggleFirstName={() => {
        setShowFirstName(true)
        setToggleInformationFieldModal(false)
      }}
      toggleLastName={() => {
        setShowLastName(true)
        setToggleInformationFieldModal(false)
      }}
      toggleCompany={() => {
        setShowCompany(true)
        setToggleInformationFieldModal(false)
      }}
      addCellNumber={addCellNumber}
      toggleCellNumber={() => {
        setToggleInformationFieldModal(false)
      }}
      addWorkNumber={addWorkNumber}
      toggleWorkNumber={() => {
        setToggleInformationFieldModal(false)
      }}
      addHomeNumber={addHomeNumber}
      toggleHomeNumber={() => {
        setToggleInformationFieldModal(false)
      }}
      toggleEmail={() => {
        setShowEmail(true)
        setToggleInformationFieldModal(false)
      }}
      toggleWebsite={() => {
        setShowWebsite(true)
        setToggleInformationFieldModal(false)
      }}
      toggleAddress={() => {
        setShowAddress(true)
        setToggleInformationFieldModal(false)
      }}
      toggleBirthday={() => {
        setShowBirthday(true)
        setToggleInformationFieldModal(false)
      }}
      toggleNotes={() => {
        setShowNotes(true)
        setToggleInformationFieldModal(false)
      }}
    />
    </Modal>
    </>

          ) :<LottieLoading />
  );
}
