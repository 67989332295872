// @ts-nocheck
import '../Stripe/StripeSelectSubscribption/StripeSelectSubscribption.scss'
import '../Stripe/ActiveSubscription/ActiveSubscription.scss'
import React, { } from "react";
import { Button, Modal, Form } from 'react-bootstrap';

interface ModalProps {
  showModal: boolean
  hideModal: () => void;
  deleteCard: () => void
  heading: string;
  leftBtn: string;
  rightBtn: string;
  subHeading: string;
  color: string;
  showInput?: boolean;
  promoId?: string;
  onChangeText?: any;
}

export const ConfirmationModal: React.FC<ModalProps> = ({ showModal, showInput, hideModal, deleteCard, promoId, onChangeText, heading, subHeading, leftBtn, rightBtn, color }) => {

  return (
    <Modal
      show={showModal}
      size='sm'
      aria-labelledby="contained-modal-title-vcenter"
      contentClassName='confirm-delete-content-modal'
      onHide={hideModal}
      centered
    >
      <Modal.Body className='subscription-modal-body'>
        <p className='subscription-text bottom '>{heading}</p>
        {showInput === true ?
          <Form className="promo-modal-input-form-container"
            onSubmit={(e) => {
              e.preventDefault()
              if (e.type === 'submit') {
                deleteCard()
              }
            }
            }
          >
            <Form.Group>
              <Form.Control
                type="text"
                name='promo'
                value={promoId}
                className='promo-modal-input-form'
                onChange={(event) => {
                  event.preventDefault()
                  onChangeText(event.target.value)
                }} />
            </Form.Group>
          </Form>
          :
          <div className='heading-container'>
            <p className='subscription-text mediumFont light'>{subHeading}</p>
          </div>
        }
        <div className='displayFlex space_around confirm-btn-container'>
          <Button className='confirmation-btn' variant='secondary' size='sm' onClick={hideModal} >{leftBtn}</Button>
          <Button className='confirmation-btn' variant={color} size='sm' onClick={deleteCard} >{rightBtn}</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}