import "./TappyConnect.scss";
import { FC } from "react";
import { Button } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import { modalSteps } from "../../utils/StaticData/staticData";

interface Props {
  onHide: () => void;
  steps?: any;
  setSteps?: any;
  name: string;
}

const TappySparkSuccess: FC<Props> = (props: any) => {

  return (
    <div className="main-body">
      <div>
        <IoClose className="closed-icon" onClick={props.onHide} />
        <p className="main-modal_tag">You’re connected!</p>
      </div>
      <p className="main-modal-text"> You’ll receive a text with {props.name} s contact info shortly</p>
      <Button
        className="main-body-spark-btn"
        variant="primary"
        type='submit'
        size="lg"
        onClick={props.onHide}
      >
        continue
      </Button>
    </div>
  );
};

export default TappySparkSuccess;
