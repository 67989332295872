import "./TappyConnect.scss";
import React, { FC, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import { modalSteps } from "../../utils/StaticData/staticData";
import { Error } from "../Error/Error";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
interface Props {
  onHide: () => void;
  steps?: any;
  setSteps?: any;
  name: string;
  shareContactCard: () => void;
}

const TappySparkConnect: FC<Props> = (props: any) => {
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });
  const [error, setError] = useState("")
  const { firstName, lastName, email, phone } = state;

  const validateForm = () => {
    let message = ''
    if (firstName === '') {
      message = 'First name cannot be empty!'
    } else if (lastName === '') {
      message = 'Last name cannot be empty!'
    } else if (email === '') {
      message = 'Email cannot be empty!'
    } else if (phone === '') {
      message = 'Phone number cannot be empty!'
    } else if (phone?.length <= 8) {
      message = 'Please enter valid phone number'
    }
    else {
      message = '';
    }

    return message;
  }


  const handleNavigation = (event: any) => {
    event.preventDefault();
    let errors = validateForm();
    if (props?.steps?.type && errors.length <= 0) {
      props?.shareContactCard({
        firstName,
        lastName,
        email,
        phone: '+' + phone
      })
    } else {
      setError(errors)
    }
  };

  return (
    <div className="main-body">
      <div>
        <IoClose className="closed-icon" onClick={props.onHide} />
        <p className="main-modal_tag">connect with {props.name} </p>
        {error.length > 0 && (
          <Error error={{ active: true, message: error }} />
        )}
        <Form className="connect-tappy_first__form">
          <div className="connect-tappy_first__form_div">
            <Form.Label className="connect-tappy_first__form__label">
              first name
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="first name"
              value={state.firstName}
              onChange={(event) => {
                setState((prev) => ({
                  ...prev,
                  firstName: event.target.value,
                }));
              }}
            />
          </div>
          <div className="connect-tappy_first__form_div">
            <Form.Label className="connect-tappy_first__form__label">
              last name
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="last name"
              value={state.lastName}
              onChange={(event) => {
                setState((prev) => ({ ...prev, lastName: event.target.value }));
              }}
            />
          </div>
        </Form>
        <Form className="connect-tappy__form">
          <Form.Label className="connect-tappy__form__label">email</Form.Label>
          <Form.Control
            type="text"
            placeholder="email"
            value={state.email}
            onChange={(event) => {
              setState((prev) => ({ ...prev, email: event.target.value }));
            }}
          />
          <Form.Label>phone</Form.Label>

          <PhoneInput
            country={'us'}
            value={state.phone}
            onChange={(value) => {
              setState((prev) => ({ ...prev, phone: value }));
            }}
            containerClass="phone-input-container"
            inputClass="phone-input-box"
            buttonClass="phone-button-box"
            inputProps={{
              name: 'phone',
              required: true,
            }}
          />
        </Form>
        <Button
          className="main-body-spark-btn"
          variant="primary"
          type='submit'
          onClick={handleNavigation}
        >
          connect
        </Button>
      </div>
      <p className="spark-modal-footer">
        By entering my mobile number, I agree to receive text messages from
        tappy. Message Frequency varies by number of contacts received. Message
        + data rates may apply. Reply STOP to unsubscribe or HELP for help.
        Learn more via our terms of service and privacy policy
      </p>
    </div>
  );
};

export default TappySparkConnect;
