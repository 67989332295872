import "./TappyConnect.scss";
import { FC, forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Modal } from "react-bootstrap";
import { modalSteps } from "../../utils/StaticData/staticData";
import TappySparkConnect from "./TappySparkConnect";
import TappySparkSuccess from "./TappySparkSuccess";

interface Props {
  show: boolean;
  onHide: () => void;
  name: string;
  shareContactCard: (value: any) => void;
}

const TappyConnect = forwardRef((props: any, ref) => {
  const { show, onHide, name, shareContactCard } = props;
  const [steps, setSteps] = useState({ type: modalSteps[0] });

  useEffect(() => {
    if (show) {
      setSteps({ type: modalSteps[0] });
    }
  }, [show]);

  useImperativeHandle(ref, () => ({
    showTappySuccess: () => {
      setSteps({ type: modalSteps[1] })
    }
  }))

  return (
    <Modal
      show={show}
      className="spark-main-modal-header"
      contentClassName="spark-main-modal-content"
      onHide={onHide}
      centered
    >
      <Modal.Body className="main-modal-body">
        {steps.type === modalSteps[0] && (
          <TappySparkConnect
            steps={steps}
            setSteps={setSteps}
            onHide={onHide}
            name={name}
            shareContactCard={shareContactCard}
          />
        )}
        {steps.type === modalSteps[1] && (
          <TappySparkSuccess
            {...props}
            onHide={onHide}
            steps={steps}
            setSteps={setSteps}
            name={name}
          />
        )}
      </Modal.Body>
    </Modal>
  );
});

export default TappyConnect;
