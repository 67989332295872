import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { useAppData } from "../hooks/useAppData";
import { getRouteName } from '../utils/google-analytics';

const usePageTracking = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);
  const { state } = useAppData();

  useEffect(() => {
    if (!window.location.href.includes('localhost') || true) {
      ReactGA.initialize(process.env.REACT_APP_TRACKING_ID, {
        testMode: false,
        gaOptions: {
          userId: state?.userData?.id,
        }
      });
    }
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      let pageViewObj = {
        hitType: "pageview",
        page: location.pathname + location.search,
      }
      const routeName = getRouteName(location.pathname);
      if (routeName) {
        pageViewObj['title'] = routeName
      }
      ReactGA.send(pageViewObj);
    }
  }, [initialized, location]);
};

export default usePageTracking;