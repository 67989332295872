import React, { useEffect, useState, useContext, createContext } from "react";
import { useHistory } from "react-router-dom";
import '../components/Stripe/ManageSubscription/ManageSubscription.scss'
import * as aws from "aws-sdk";

import axiosApi from "../axiosApi";
import { googleAnalyticsCustomEvent } from "../utils/google-analytics";
import { StripeTrialModal } from "./StripeModal/StripeTrialModal";
import { availableThemes } from "../utils/StaticData/staticData";

export interface AppContextInterface {
  userData: any;
  session: number;
  selectedProfile: any;
  profiles: any;
  socials: any;
  loading: boolean;
  state: any,
  subscriptionData: any,
  setShowStripeModal: any,
  showStripeModal: any,
  setSubscriptionData: any,
  setState: any,
  checkUserSession: any,
  showQR: boolean,
  setShowQR: any,
  handleLogout: any,
  history: any,
  isActiveSubscription: any,
  subscriptionDetails: () => any,
  abortS3Upload: () => any,
  uploadOnS3: (value: any, fn?: any) => any,
  deleteFromS3: (value: any, fn?: any) => any,
  handleHistory: any,
  goBack: any,
  setLoading: any,
  getSessionData: any,
  setAccentColor: any,
  accentColor: any,
  accentTextColor: any,
  accentBackgroundColor: any,
  setAccentTextColor: any,
  setAccentBackgroundColor: any,
  buttonShape: string,
  setButtonShape: (select: string) => void,
  buttonColor: any,
  setButtonColor: (select: any) => void,
  buttonStrokes: string,
  setButtonStrokes: (select: string) => void,
  theme: string,
  setTheme: (select: string) => void,
  hideTappyFooter: boolean,
  setHideTappyFooter: (select: boolean) => void,
  receiveContact: boolean,
  setReceiveContact: (select: boolean) => void,
  profileBackground: string,
  setProfileBackground: (select: string) => void,
  selectedDesignTab: string,
  setSelectedDesignTab: (select: string) => void,
  fontFamily: string,
  setFontfamily: (select: string) => void,
  isProfileUpdated: boolean,
  setIsProfileUpdated: (select: boolean) => void,
  animLoader: boolean,
  setAnimLoader: (select: boolean) => void,
  uploadLoader: boolean,
  setUploadLoader: (select: boolean) => void,
  socialsState: any,
  setSocialState: (select: any) => void
}

const AppContext = createContext<AppContextInterface | null>(null);

export function useAppData() {
  return useContext(AppContext);
}

export const AppDataProvider: React.FC<React.ReactNode> = ({ children }) => {

  const initialState = {
    userData: {},
    session: -1,
    selectedProfile: null,
    profiles: [],
    socials: [],
    loading: false,
  }
  const [state, setState] = useState(initialState);
  const defaultAccentColor = "#567df4";
  const defaultAccentTextColor = "#ffffff";
  const defaultAccentBackgroundColor = "linear-gradient(180deg, #0A85F7 0%, #00D1FF 100%)";
  const defaultButtonColor = { "r": 14, "g": 15, "b": 19, "a": 1 }
  const defaultButtonStrokes = "#0A0A0A";
  const defaultFontFamily = "Poppins";
  const defaultButtonShape = "rounded";
  // s3 credentitals
  const region = process.env.REACT_APP_AWS_DEFAULT_REGION;
  const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
  const secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;

  // Initialize the Amazon Cognito credentials provider
  const s3 = new aws.S3({
    region,
    accessKeyId,
    secretAccessKey,
    signatureVersion: 'v4'
  });

  const [animLoader, setAnimLoader] = useState(false);
  const [uploadLoader, setUploadLoader] = useState(false);
  const [showQR, setShowQR] = useState(false);
  const [accentColor, setAccentColor] = useState(defaultAccentColor)
  const [accentTextColor, setAccentTextColor] = useState(defaultAccentTextColor)
  const [accentBackgroundColor, setAccentBackgroundColor] = useState(defaultAccentBackgroundColor)
  const [profileBackground, setProfileBackground] = useState(defaultAccentBackgroundColor);
  const [isProfileUpdated, setIsProfileUpdated] = useState(false);
  const [showStripeModal, setShowStripeModal] = useState(false);
  const [buttonShape, setButtonShape] = useState("");
  const [buttonColor, setButtonColor] = useState(defaultButtonColor);
  const [buttonStrokes, setButtonStrokes] = useState(defaultButtonStrokes);
  const [theme, setTheme] = useState("");
  const [hideTappyFooter, setHideTappyFooter] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState({});
  const [receiveContact, setReceiveContact] = useState(false);
  const [isActiveSubscription, setIsActiveSubscription] = useState<any>({ newUser: true, active: false });
  const [selectedDesignTab, setSelectedDesignTab] = useState("0");
  const [fontFamily, setFontfamily] = useState(defaultFontFamily)
  const [socialsState, setSocialState] = useState([])
  const history = useHistory();
  const awsRef = React.useRef<any>(null);

  useEffect(() => {
    getSessionData();
    if (state.session !== -1) {
      subscriptionDetails(() => {

      })
    }
  }, [state.session])

  const handleActiveSubscription = (newUser: boolean, active: boolean) => {
    setLoading(false)
    setIsActiveSubscription({
      newUser: newUser,
      active: active
    })
  }

  const uploadOnS3 = async (params: any, callback: (value?: boolean, imageProgress?: any) => void) => {
    try {
      awsRef.current = await s3.putObject(params, async function (err: any, data) {
        if (err) {
          callback(false)
        } else {
          callback(true)
        }
      }).on('httpUploadProgress', (progress: any) => {
        if (progress?.total && progress?.loaded) {
          const { loaded, total } = progress
          let perc = ((loaded / total) * 100).toFixed(0)
          callback(false, parseInt(perc))
        }
      });
    } catch (err: any) {
      callback(false)
    }
  }

  const deleteFromS3 = (params: any, callback: (value?: boolean, data?: any) => void) => {
    try {
      s3.deleteObject(params, (error, value) => {
        if (error) {
          callback(false);
        } else {
          callback(true);
        }
      });
    } catch (err) {
      callback(false);
    }
  }

  const abortS3Upload = () => {
    awsRef?.current?.abort()
  }

  async function subscriptionDetails(callback: (value?: any) => void) {
    try {
      const accessToken = localStorage.getItem('accessToken');
      setLoading(true)
      const response = await axiosApi.get(`user/subscription/plan`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      if (response?.data?.status) {
        if (response?.data?.subscriptions) {
          if (response?.data?.subscriptions?.data?.[0]?.status === "active" || response?.data?.subscriptions?.data?.[0]?.status === "trialing") {
            handleActiveSubscription(false, true)
          }
          else {
            handleActiveSubscription(false, false)
          }
        } else {
          handleActiveSubscription(true, false)
        }
        setSubscriptionData(response?.data)
      } else {
        handleActiveSubscription(true, false)
        setSubscriptionData({})
        setLoading(false)
      }
      callback(true)
    } catch (error: any) {
      callback(true)
      setSubscriptionData({})
      setLoading(false)
      handleActiveSubscription(true, false)
      // console.log(error?.response);
    }
  }

  const hideModal = () => setShowStripeModal(false)

  const handleHistory = (url: string, params?: any) => {
    if (params) {
      history.push(url, { params });
    } else {
      history.push(url);
    }
  }

  const goBack = () => {
    history.goBack();
  }

  const setLoading = (status: boolean) => {
    setState((prev) => ({ ...prev, loading: status }))
  }

  const checkUserSession = async () => {
    const accessToken = localStorage.getItem('accessToken');


    if (accessToken) {
      try {
        const response = await axiosApi.get(`user`,);

        //console.log(state);

        if (response.data) {
          setState(() => ({
            ...state,
            userData: response.data,
            session: response.data.id
          }))
          //console.log(state);
        } else {
          handleHistory('/login');
        }
      } catch (error) {
        setLoading(false);
      }

    } else {
      setLoading(false);
    }
  }

  async function getSessionData() {

    setAnimLoader(true);
    if (state.session > -1) {
      //Use axiosApi to get session data
      try {
        const response = await axiosApi.get(`user/${state.session}/profile`);
        const socialsResponse = await axiosApi.get(`socials`);

        const userProfiles = response.data.profiles;

        if (userProfiles.length > 0) {
          const userProfile = userProfiles[0];
          const userProfileApiUrl = `user/${state.session}/profile/${userProfile.id}`
          const userProfileCardApiUrl = `${userProfileApiUrl}/card`;
          const userProfileSocialsApiUrl = `${userProfileApiUrl}/socials`;
          const userProfileContactApiUrl = `${userProfileApiUrl}/contact`;

          await Promise.allSettled([
            axiosApi.get(userProfileCardApiUrl),
            axiosApi.get(userProfileSocialsApiUrl),
            axiosApi.get(userProfileContactApiUrl)]).then((res) => {
              res.map((item) => {
                if (item.status === "fulfilled") {
                  if (item.value.config.url === userProfileCardApiUrl) {
                    userProfiles[0].cards = item.value.data.cards
                  }
                  else if (item.value.config.url === userProfileSocialsApiUrl) {
                    userProfiles[0].socials = item.value.data
                  }
                  else if (item.value.config.url === userProfileContactApiUrl) {
                    userProfiles[0].contactCard = item.value.data
                  }
                }
              })

            })
        }
        if (response.data.profiles.length) {
          setState((prevState) => ({
            ...prevState,
            selectedProfile: userProfiles[0],
            profiles: response.data.profiles,
            socials: socialsResponse.data
          }))
          setSocialState(userProfiles[0].socials)
          setAccentColor(userProfiles[0].accent_color)
          setAccentTextColor(userProfiles[0].accent_text_color)
          setAccentBackgroundColor(userProfiles[0].background_color)
          setProfileBackground(userProfiles[0].background_color)
          setButtonShape(userProfiles[0].button_shape || defaultButtonShape)
          setButtonStrokes(userProfiles[0].button_stroke)
          setTheme(userProfiles[0].theme || availableThemes[1].key)
          setReceiveContact(userProfiles[0].receive_contact)
          setFontfamily(userProfiles[0].font_family || defaultFontFamily)
          if (isActiveSubscription.active) {
            setHideTappyFooter(userProfiles[0].hide_tappy_footer)
          }
          setButtonColor(userProfiles[0].button_color ? JSON.parse(userProfiles[0].button_color) : defaultButtonColor)
        }
      } catch (error: any) {
        setAnimLoader(false);
        // console.log(error.response);
      }
    } else {
      //console.log("Not User Session Found!");
    }
    setAnimLoader(false);

  }

  const handleLogout = async () => {
    setLoading(true);
    const accessToken = localStorage.getItem('accessToken');

    if (accessToken) {
      try {
        await axiosApi.get(`user/logout`);
        const stateData: any = state.userData
        const event = {
          action: 'user_logout',
          value: stateData?.username,
          key: 'logout'
        }
        googleAnalyticsCustomEvent(event)
        localStorage.removeItem('lastTime')
        localStorage.removeItem('accessToken');
        state.session = -1;
        setAccentColor(defaultAccentColor)
        setState(initialState)
        handleHistory("/");
      } catch (error) {
        //console.log(error);
        handleHistory("/");
        setLoading(false);
      }
    }

    setLoading(false);
  }

  const value: any = {
    state,
    subscriptionData,
    setShowStripeModal,
    showStripeModal,
    setSubscriptionData,
    setState: (e: any) => {
      // console.log({ e });
      setState(e);
      setIsProfileUpdated(!isProfileUpdated);
    },
    checkUserSession,
    handleLogout,
    subscriptionDetails,
    history,
    handleHistory,
    goBack,
    setLoading,
    getSessionData,
    setAccentColor,
    setAccentTextColor,
    setAccentBackgroundColor,
    accentColor,
    showQR,
    setShowQR,
    accentTextColor,
    accentBackgroundColor,
    buttonShape,
    setButtonShape,
    buttonColor,
    uploadOnS3,
    deleteFromS3,
    abortS3Upload,
    setButtonColor,
    buttonStrokes,
    setButtonStrokes,
    theme,
    setTheme,
    isActiveSubscription,
    hideTappyFooter,
    setHideTappyFooter,
    receiveContact,
    setReceiveContact,
    profileBackground,
    setProfileBackground: (e: any) => {
      // console.log({ e });
      !profileBackground?.includes("linear-gradient") &&
        setProfileBackground('');
      setProfileBackground(e);
      setIsProfileUpdated(!isProfileUpdated);
    },
    selectedDesignTab,
    setSelectedDesignTab,
    fontFamily,
    setFontfamily,
    animLoader,
    setUploadLoader,
    uploadLoader,
    socialsState,
    setSocialState: (e: any) => {
      console.log({ e });
      setSocialState(e);
      setIsProfileUpdated(!isProfileUpdated);
    }
  };

  return (
    <AppContext.Provider value={value}>
      {children}
      <StripeTrialModal
        hideModal={hideModal}
        showStripeModal={showStripeModal}
        handleHistory={handleHistory}
      />
    </AppContext.Provider>
  )
}

