import { FaCheckCircle } from 'react-icons/fa';
import './StripeSelectSubscribption.scss'
import React, { useEffect, useState } from "react";
import { Button, Spinner } from 'react-bootstrap';
import { modalSteps, paymentMethods, proFeatures } from '../../../utils/StaticData/staticData';
import axiosApi from '../../../axiosApi';
import notification from "../../Notification";
import { IoArrowBack } from "react-icons/io5";
import { AppContextInterface, useAppData } from '../../../hooks/useAppData';
import { googleAnalyticsCustomEvent } from '../../../utils/google-analytics';

interface Props {
  handleHistory: (url: string, params?: any) => void;
  params?: any;
  steps?: any;
  setSteps?: any;
}

export const StripeSelectSubscribptionComp: React.FC<Props> = (props) => {

  const {
    subscriptionData,
  } = useAppData() as AppContextInterface;

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>(paymentMethods[0].name);
  const [selectedPlan, setSelectedPlan] = useState<any>({});
  const [tappyPlans, setTappyPlans] = useState<any>([]);
  const [animationLoader, setAnimationLoader] = useState<boolean>(false);
  const alreadyRegistered = props?.params?.alreadyRegistered ? true : false
  const alreadyUsedTrail = subscriptionData?.subscription_status_auth_user?.first_trial == 1 ? true : false

  useEffect(() => {
    const event = {
      action: `stripe`,
      value: 'On Select payment method page',
      key: 'stripe_payment_page'
    }
    googleAnalyticsCustomEvent(event)
    getTappyPlans()
  }, [])

  const captilize = (text: string) => {
    if (text?.length > 1) {
      return text.slice(0, 1).toUpperCase() + text.slice(1, text.length).toLowerCase()
    }
    return ''
  }

  const getTappyPlans = async () => {

    setAnimationLoader(true)

    try {
      const response = await axiosApi.get('user/plans')
      if (response.data.status) {
        setAnimationLoader(false)
        response?.data?.data?.forEach((item: any) => {
          if (item.slug === 'monthly') {
            item['moreInfo'] = 'Cancel anytime'
          } else {
            item['moreInfo'] = '(save 28%)'
          }
        })
        setSelectedPlan(response.data.data[0])
        setTappyPlans(response.data.data)
      }
    } catch (err) {
      notification({
        message: "Something went wrong!",
        type: "danger",

      });
      setAnimationLoader(false)
    }
  }

  const handleNavigation = (type?: any) => {
    const params = {
      paymentMethod: selectedPaymentMethod,
      plan: selectedPlan,
      cardDetails: alreadyRegistered ? props?.params?.paymentMethod : null
    }
    if (type === 'goback') {
      props?.setSteps({ type: modalSteps[0], param: '' })
    }
    else if (props?.steps?.type) {
      props?.setSteps({ type: modalSteps[2], param: params })
    } else {
      props?.handleHistory('/addPaymentMethod', { ...params })
    }

  }

  return (
    <>
      {animationLoader ? <div className="background-upload-loader"><Spinner animation="border" /></div> : null}
      <div className={`subscribption ${props?.steps?.type ? '' : 'subscribption-main'}`}>
        <div className='subscibption-container'>
          <div className='back-btn-container'>
            {props?.steps?.type && <IoArrowBack size={21} className='back-btn' onClick={() => handleNavigation('goback')} />}
            <p className='normal-text bold'>{`${alreadyUsedTrail ? 'Select your plan' : 'Start your free trial of pro'}`}</p>
          </div>
          <div className='displayFlex space_between'>
            {tappyPlans?.map((item: any) =>
              <div key={item.id} className={`displayFlex space_around payment_types ${selectedPlan?.slug === item.slug ? 'selected' : null}`}
                onClick={() => setSelectedPlan(item)}
              >
                <p className='normal-text'>{captilize(item.slug)}</p>
                <div className='displayFlex column'>
                  <p className='normal-text medium bold'>${item.price}</p>
                  <p className='normal-text small'>{item.moreInfo}</p>
                </div>
              </div>
            )}
          </div>
          <div className='displayFlex column last'>
            {paymentMethods.map((item) =>
              <div
                className={`displayFlex payment-method-container ${selectedPaymentMethod === item.name ? 'selected' : null}`}
                onClick={() => setSelectedPaymentMethod(item.name)}
              >
                <div className='icon-container'>
                  {item.icon}
                </div>
                <p className='normal-text'>{item.name}</p>
              </div>
            )}
          </div>
          {proFeatures.map((item, id) => (alreadyUsedTrail ? !item.firstTime : !item.secondTime) &&
            <div className='subs-check' >
              <div key={id} className='subs-check-box' >
                <FaCheckCircle className='subs-check-box_circle' />
                <p className='subs-text' >{item?.label}</p>
              </div>
            </div>
          )}
          <Button className="react-btn" variant="primary" size='lg'
            onClick={handleNavigation}
          >
            {alreadyUsedTrail ? 'Subscribe now' : 'Start your free 14 day trial'}
          </Button>
        </div>
        <p className='footer-text'>
          By clicking {alreadyUsedTrail ? '“Subscribe now”' : '“Start your free 14 day trial”'} you agree to Tappy's Terms of Service and Privacy Policy. Your credit card will be charged monthly and your fees may vary based on how we bill
        </p>
      </div>
    </>
  );
}