import "./EditTheme.scss";
import React, { useEffect, useState } from "react";
import {
  Card,
  Form,
  Button as DefaultButton,
  Tab,
  Tabs,
  Row,
  Container,
  Col,
} from "react-bootstrap";
import { useAppData, AppContextInterface } from "../../hooks/useAppData";
import Button from "../Button";
import { EditBackground } from "./EditBackground/EditBackground";
import { EditButtons } from "./EditButtons/EditButtons";
import { EditText } from "./EditText/EditText";
import { shortenString } from "../../utils/google-analytics";
import { availableThemes, imagesFolderName, videosFolderName } from "../../utils/StaticData/staticData";
import { LottieLoading } from "../LottieLoading/LottieLoading";
import { getSocialIcon } from "../../utils/global/global";

interface State {
  session: number;
  userData: {};
  profiles: any;
  socials: any;
  selectedProfile: any;
}

interface Props {
  state: State;
  setState: React.Dispatch<React.SetStateAction<any>>;
}

export const EditTheme: React.FC<Props> = (props) => {
  const {
    state,
    accentTextColor,
    buttonShape,
    theme,
    setTheme,
    setSelectedDesignTab,
    profileBackground,
    buttonColor,
    buttonStrokes,
    fontFamily,
    socialsState
  } = useAppData() as AppContextInterface;

  const dummmyArr = ["", "", "", ""]

  const [animationLoader, setAnimationLoader] = useState(false);
  const [imageLoader, setImageLoader] = useState(false);

  useEffect(() => {
    setImageLoader(true)
  }, [profileBackground])

  const handleSelect = (key: any) => {
    setSelectedDesignTab(key);
  };

  const getThemeBlock = (themeName: string, index: number, title: string) => {
    if (props.state && props.state.selectedProfile) {

      const isCurrentTheme = themeName === theme ? "current" : ""
      const isDarkTheme = themeName !== 'dark' ? "current" : ""

      return (
        <Form.Group
          className={`theme-block-select ${isCurrentTheme}`}
          controlId={`theme-submit-${index}`}
          key={index}
        >
          <DefaultButton
            className={`theme-${themeName} ${isCurrentTheme}`}
            variant="light"
            onClick={() => setTheme(themeName)}
            value={themeName}
          >
            <div className="live-theme-layout-container">
              <div className={`live-theme-layout-header-container ${isDarkTheme}`}>
                <div className={`live-theme-layout-profile-image ${isDarkTheme}`} />
                <div className={`live-theme-layout-profile-details ${isDarkTheme}`} />
              </div>
              <div className="live-theme-layout-bottom-container">
                {dummmyArr.map((_,index) =>
                  <div className="live-theme-layout-socials" key={index}/>
                )}
              </div>
            </div>
          </DefaultButton>
          <Form.Label>{title}</Form.Label>
        </Form.Group>
      );
    }
  };

  const livePreview = () => {

    const profileImage = state?.selectedProfile?.photo || "/images/profile-blank-img.png"
    return (
      <Container
        className="live-preview-container"
      >
        {theme === "dark" ? (
          <div>
            <Row className="row-preview-container">
              <Col
                xs={5}
                className="container-col avtr-col-container"
                style={{ paddingRight: "8px" }}
              >
                <img
                  alt="Profile Avatar"
                  style={{
                    objectFit: "cover",
                    width: "60px",
                    height: "60px",
                    borderRadius: "50%",
                  }}
                  src={profileImage}
                />
              </Col>
              <Col
                xs={7}
                className="container-col preview-text-col-container"
                style={{ padding: "0 8px" }}
              >
                <div
                  style={{
                    color: accentTextColor,
                    fontFamily: fontFamily,
                    marginBottom: "5px",
                    fontWeight: 600,
                    fontSize: '12px'
                  }}
                >
                  {state?.profiles[0]?.name}
                </div>
                <Button
                  className={`button-live-preview-${buttonShape}`}
                  style={{
                    color: accentTextColor,
                    border: `1px solid ${buttonStrokes}`,
                    fontFamily: fontFamily,
                    backgroundColor: `rgba(${buttonColor?.r}, ${buttonColor?.g}, ${buttonColor?.b}, ${buttonColor?.a})`,
                    fontSize: '10px',
                    width: '100%',
                    backdropFilter: 'blur(3px)',
                    margin: 'auto',
                    padding: '4px'
                  }}
                >
                  Add to Contacts
                </Button>
              </Col>
              <Col
                xs={12}
                className="container-col avtr-col-container"
                style={{ paddingRight: "8px" }}
              >
                <div
                  style={{
                    fontSize: "10px",
                    margin: "5px 0",
                  }}
                >
                  <span
                    style={{ color: accentTextColor, fontFamily: fontFamily, whiteSpace: 'pre-line' }}
                    className="profile-bio-preview"
                  >
                    {state?.profiles[0]?.bio}
                  </span>
                </div>
              </Col>
            </Row>
          </div>
        ) : (
          <div className="live-preview-inner-container">
            <div className="profile-pic-container">
              <div className="profile-picture-background">
                <img
                  alt="Profile Avatar"
                  style={{
                    objectFit: "cover",
                    width: "60px",
                    height: "60px",
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: "12px",
                  }}
                  src={profileImage}
                />
              </div>
            </div>
            <div
              style={{
                color: accentTextColor,
                fontFamily: fontFamily,
                marginBottom: "5px",
                textAlign: "center",
                fontWeight: 600,
                fontSize: '12px'
              }}
            >
              {state?.profiles[0]?.name}
            </div>
            <div style={{ textAlign: "center" }}>
              <div
                className={`button-live-preview-${buttonShape}`}
                style={{
                  color: accentTextColor,
                  backgroundColor: `rgba(${buttonColor?.r}, ${buttonColor?.g}, ${buttonColor?.b}, ${buttonColor?.a})`,
                  border: `1px solid ${buttonStrokes}`,
                  fontFamily: fontFamily,
                  fontSize: '10px',
                  width: '60%',
                  backdropFilter: 'blur(3px)',
                  margin: 'auto',
                  padding: '4px'
                }}
              >
                Add to Contacts
              </div>
            </div>
            <div
              style={{ textAlign: "center", fontSize: "10px", margin: "8px 0" }}
            >
              <span className="profile-bio-preview" style={{ color: accentTextColor, fontFamily: fontFamily, whiteSpace: 'pre-line' }}>
                {state?.profiles[0]?.bio}
              </span>
            </div>
          </div>
        )}
        {socialsState?.slice(0, 3).map((profile: any,index:number) => {
          return (
            <div style={{ position: "relative", marginTop: "10px", zIndex: 9 }} key={index}>
              <div style={{ position: "absolute", zIndex: 99, top: '-2px' }}>
                <img
                  src={getSocialIcon(profile.name)}
                  style={{ width: "34px", height: "34px" }}
                  className="profile-social-img"
                  alt="socialImage"
                />
              </div>
              <div
                style={{
                  color: accentTextColor,
                  fontFamily: fontFamily,
                  backgroundColor: `rgba(${buttonColor?.r}, ${buttonColor?.g}, ${buttonColor?.b}, ${buttonColor?.a})`,
                  border: `1px solid ${buttonStrokes}`,
                  backdropFilter: 'blur(3px)',
                  marginLeft: '8px',
                  fontSize: '11px'
                }}
                className={`social-link-container button-live-preview-${buttonShape}`}
              >
                {profile?.pivot?.username.includes('www.linkedin.com') ? 'linkedin' : shortenString(profile?.pivot?.username, 13)}
              </div>
            </div>
          )
        })}
      </Container>
    );
  };

  return (
    <>
      {/* ------------------------New design----------------------------- */}
      {/* {props?.state?.selectedProfile?.theme ? 
             themeLivePreview(props?.state?.selectedProfile?.theme):
            <div className={`theme-live-preview`} >
                live preview
            </div>} */}
      <div
        style={{
          width: "185px",
          height: "300px",
          borderRadius: "23px",
          position: "relative",
        }}
      >
        {profileBackground?.includes(videosFolderName) && (
          <>
            <video
              className="videoConntainer"
              style={{ borderRadius: "20px", display: imageLoader ? 'none' : 'block' }}
              autoPlay
              onLoadedData={() => setImageLoader(false)}
              src={profileBackground}
              muted
              loop
              playsInline
            />
            {imageLoader && <div className="videoConntainer">
              <LottieLoading showImageLoader={true} />
            </div>}
          </>
        )}
        {profileBackground?.includes(imagesFolderName) && (
          <>
            <img
              alt="Profile Avatar"
              className="linear-gradient-background"
              onLoad={() => setImageLoader(false)}
              src={profileBackground}
            />
            {imageLoader && <div className="linear-gradient-background">
              <LottieLoading showImageLoader={true} />
            </div>}
          </>
        )}
        {profileBackground?.includes("linear-gradient") || profileBackground?.includes("rgba") ? (
          <div
            className="linear-gradient-background"
            style={{
              backgroundImage: profileBackground?.includes("linear-gradient")
                ? profileBackground
                : "",
              backgroundColor: profileBackground,
              backgroundSize: "cover",
              borderRadius: "26px",
            }}
          />
        ) : null}
        {livePreview()}
      </div>
      <Tabs
        defaultActiveKey="0"
        id="uncontrolled-tab-example"
        className="mb-3"
        onSelect={(key) => handleSelect(key)}
      >
        <Tab eventKey="0" title="Layouts" className="theme-tab">
          <Card className="theme-editor my-3">
            <Card.Body>
              <div className="theme-selector d-block">
                {availableThemes &&
                  availableThemes.map((themeName, index) =>
                    getThemeBlock(themeName.key, index, themeName.title)
                  )}
              </div>
            </Card.Body>
          </Card>
        </Tab>
        <Tab eventKey="1" title="Background">
          <EditBackground
            setAnimationLoader={setAnimationLoader}
            animationLoader={animationLoader}
          />
        </Tab>
        <Tab eventKey="2" title="Buttons">
          <EditButtons />
        </Tab>
        <Tab eventKey="3" title="Text">
          <EditText />
        </Tab>
      </Tabs>
    </>
  );
};
